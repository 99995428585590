<template>
    <div id="webhook">
        <!-- <div class="content-header" style="width:100%">
            <div class="leftInfo">
                <span class="headerName">{{activePageInfo.headerName}}</span>

                <div v-if="botVersion != 2" class="opt-button" style="margin-right: 32px" v-show="!isShowEmptyStatus">
                    <el-button v-if="RP_Visible('OPEN_WEBHOOK_UPDATE')" type="primary" plain @click="saveConf" :disabled="(isDisabled && !RP_Visible('OPEN_WEBHOOK_UPDATE')) || isRelease">保存配置</el-button>
                    <el-button v-if="RP_Visible('OPEN_WEBHOOK_RELEASE')" @click="release" :disabled="releaseIsDisabled">发布</el-button>
                </div>
            </div>
        </div> -->
         <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenaljicheng"></i>
                <span class="page-name">接口对接</span>
            </div>
            <div slot="top-header-right" class="top-header-right">
               <div class="opt-button" style="margin-right: 32px" v-show="!isShowEmptyStatus">
                    <el-button v-if="RP_Visible('OPEN_WEBHOOK_UPDATE')" type="primary" plain @click="saveConf" :disabled="(isDisabled && !RP_Visible('OPEN_WEBHOOK_UPDATE')) || isRelease">保存配置</el-button>
                    <el-button v-if="RP_Visible('OPEN_WEBHOOK_RELEASE')" @click="release" :disabled="releaseIsDisabled">发布</el-button>
                </div>
            </div>
        </page-top-header>
        
        <div class="webhook-content">
            <div class="subMenu">
                <el-menu
                    :default-openeds="['/main/form']"
                    router
                    :default-active = "currentPath"
                    class="el-menu-vertical-demo">
                    <el-menu-item index="/main/webhook" style="width: 150px;padding-left: 20px" v-if="RP_Visible('OPEN_WEBHOOK')" :class="{activeClass:this.$route.path==='/main/webhook'}">
                        <i class="el-icon-connection"></i>
                        <span slot="title">Webhook</span>
                    </el-menu-item>
                     <el-submenu v-if="RP_Visible('OPEN_FORM')" index="/main/form">
                        <template slot="title">
                        <i class="el-icon-set-up"></i>
                        <span>自定义表单</span>
                        </template>
                        <el-menu-item-group>
                        <el-menu-item index="/main/fieldManage" :class="{activeClass:this.$route.path==='/main/fieldManage'}">字段管理</el-menu-item>
                        <el-menu-item index="/main/formManage" :class="{activeClass:this.$route.path==='/main/formManage'}">表单管理</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item
                        index="/main/epidemic"
                        v-if="isShow"
                        :class="{ activeClass: this.$route.path === '/main/epidemic',epidemic_view: true }"
                        >
                        <template slot="title">
                           <i class="arsenal_icon" style="font-size:16px;margin-right:10px">&#xed6d;</i>
                            <span>疫区管理</span>
                        </template>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="webhook-list-no-acc" v-if="botVersion == 2">
                <VersionTip tipIndex="1"/>
            </div>
            <div v-if="botVersion != 2" class="webhook-list">
                <div class="webhook-search" v-show="webhookDirList.length > 0">
                    <el-input
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        v-model="searchDirKeyword" @keyup.enter.native="getWebhookDirList">
                    </el-input>
                </div>
                
                <div class="conllection-list" v-loading="loading">
                    <div class="emptyTree" v-show="webhookDirList==null || webhookDirList.length < 1">
                        <img src="../../assets/images/emptyTree.png" alt="" class="">
                        <p>暂无目录，请先创建目录</p>
                        <el-button type="primary" @click="appendFirstNode">创建目录</el-button>
                    </div>
                    <el-tree
                        ref="tree"
                        :data="webhookDirList"
                        node-key="id"
                        default-expand-all
                        highlight-current= "true"
                        :expand-on-click-node="false">
                        <span @click="() => showWebhookDetail(data)" @mouseover="showPopover(node.id)" @mouseleave="hiddenPopover(node.id)" class="custom-tree-node" slot-scope="{ node, data }">
                            <span style="display:flex;">
                                <span style="color:#409eff;font-weight:600" v-if="data.type=='api'">API</span>
                                <i v-else-if="data.type=='dir'" class="arsenal_icon arsenalwenjianjia"></i> 
                                <span style="display: flex;justify-content: space-around;flex-direction: column;">
                                    <span style="word-break: break-all;white-space: break-spaces;line-height: 18px !important;text-align: left;display: inline-block;max-width: 110px;text-overflow: ellipsis !important;vertical-align: sub;" class="label-name">{{ data.label }}<span v-if="data.type=='dir'">（{{data.count}}）</span></span>
                                </span>
                                <span style="display: flex;justify-content: space-around;flex-direction: column;">
                                    <el-tag v-if="data.type=='api' && data.status==1" size="small">已发布</el-tag>
                                </span>
                            </span>
                            
                            <el-popover
                                :id="'popover'+node.id"
                                class="eltree"
                                placement="bottom"
                                width="auto"
                                trigger="hover"
                                >
                                <el-button
                                    type="text"
                                    size="medium"
                                    v-show="data.type=='dir' && RP_Visible('OPEN_WEBHOOK_DIR_ADD')"
                                    @click="() => append(node,data)">
                                    <i class="arsenal_icon arsenaltianjiawenjian" style="margin-right:10px;font-size:14px"></i>
                                    <span>添加目录</span>
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    v-show="data.type=='dir' && RP_Visible('OPEN_WEBHOOK_ADD')"
                                    @click="() => addleaf(data)">
                                    <i class="arsenal_icon arsenalAPI" style="margin-right:10px;font-size:14px"></i>
                                    <span>添加webhook</span>
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    v-show="data.type=='dir' && RP_Visible('OPEN_WEBHOOK_DIR_UPDATE')"
                                    @click="() => reName(node, data)"
                                    >
                                    <i class="arsenal_icon arsenaledit1" style="margin-right:10px;font-size:14px"></i>
                                    <span>重命名</span>
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    v-show="data.type=='api' && RP_Visible('OPEN_WEBHOOK_ADD')"
                                    @click="() => copyWebhook(node, data)">
                                    <i class="arsenal_icon arsenalfuzhi" style="margin-right:10px;font-size:14px"></i>
                                    <span>复制webhook</span>
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    v-if="data.type=='api' && RP_Visible('OPEN_WEBHOOK_DELETE')"
                                    @click="() => remove(node, data)">
                                    <i class="arsenal_icon arsenalshanchu" style="margin-right:10px;font-size:14px"></i>
                                    <span>删除webhook</span>
                                </el-button>
                                <el-button
                                    type="text"
                                    size="medium"
                                    v-if="data.type=='dir' && RP_Visible('OPEN_WEBHOOK_DIR_DELETE')"
                                    @click="() => remove(node, data)">
                                    <i class="arsenal_icon arsenalshanchu" style="margin-right:10px;font-size:14px"></i>
                                    <span>删除目录</span>
                                </el-button>
                                <el-button v-if="(data.type=='dir' && (RP_Visible('OPEN_WEBHOOK_DIR_ADD') || RP_Visible('OPEN_WEBHOOK_ADD') || RP_Visible('OPEN_WEBHOOK_DIR_UPDATE') || RP_Visible('OPEN_WEBHOOK_DIR_DELETE'))) || (data.type=='api' && (RP_Visible('OPEN_WEBHOOK_DELETE') || RP_Visible('OPEN_WEBHOOK_ADD')))" style="border:none;padding:5px" slot="reference"><i class="arsenal_icon arsenalellipsis-h-solid"></i></el-button>
                            </el-popover>
                        </span>
                    </el-tree>
                </div>
            </div>
            <div v-if="botVersion != 2" class="webhook-edit"  v-show="isShowEmptyStatus">
                <div class="webhook-edit-empty">
                    <img width="80%" src="../../assets/images/emptyimg.png">
                    <p>当前未选中任何Webook，请在左侧进行添加或选择。</p>
                </div>
            </div>
            <div v-if="botVersion != 2" class="webhook-edit" v-show="!isShowEmptyStatus">
                <div class="webhook-info">
                    <div class="webhook-name">
                        <span>名称</span>
                        <el-input  placeholder="请输入内容" v-model="webhookDetail.name" :disabled="isDisabled"></el-input>
                    </div>
                    <div class="webhook-desc">
                        <span>描述</span>
                        <el-input  placeholder="请输入内容" v-model="webhookDetail.description" :disabled="isDisabled"></el-input>
                    </div>
                </div>
                <div class="connect">
                    <div class="connect-url">
                        <el-input placeholder="请输入内容" v-model="webhookDetail.url" :disabled="isDisabled">
                            <el-select slot="prepend" v-model="webhookDetail.method" placeholder="请选择" :disabled="isDisabled">
                                <el-option
                                v-for="item in methodOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-input>
                    </div>
                    <el-button type="primary" size="mini" @click="sendRequest">连 接</el-button>
                </div>
                <div class="request-item" v-show="isShowRequest">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="参数(Params)" name="first" >

                            <div class="title">
                                <el-radio-group :disabled="isDisabled" v-model="paramType">
                                    <el-radio label="none" >无</el-radio>
                                    <el-radio label="form-data">form-data</el-radio>
                                    <el-radio label="x-www-form-urlencoded">x-www-form-urlencoded</el-radio>
                                    <el-radio label="raw">raw</el-radio>
                                    <el-radio label="binary">binary</el-radio>
                                </el-radio-group>
                            </div>

                            <div class="params title">
                                <div class="params-name">参数名称</div>
                                <div class="params-ismust">是否必需</div>
                                <div class="params-value">默认值</div>
                                <!-- <div class="params-entity">关联实体</div> -->
                                <div class="params-delete"></div>
                            </div>
                            <div class="content" id="paramsContent">
                                <div class="params" v-for="(param,index) in paramsConf" v-bind:key="index">
                                    <div class="params-name">
                                        <el-input placeholder="请输入内容" v-model="param.key" :disabled="isDisabled"></el-input>
                                    </div>
                                    <div class="params-ismust">
                                        <el-checkbox v-model="param.required" :disabled="isDisabled"></el-checkbox>
                                    </div>
                                    <div class="params-value">
                                        <el-input placeholder="请输入内容" v-model="param.value" :disabled="isDisabled"></el-input>
                                    </div>
                                    <!-- <div class="params-entity">
                                        <div class="block">
                                            <el-cascader :options="entityOptions" :props="cascaderProps" v-model="param.entityName" :show-all-levels="false" @change="handleEntityChange" :disabled="isDisabled">
                                                <template slot-scope="{ node, data }">
                                                    <span>{{ data.label }}</span>
                                                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                                </template>
                                            </el-cascader>
                                        </div>
                                    </div> -->
                                    <div class="params-delete">
                                        <i
                                                :class="['arsenal_icon','arsenaltrash-alt-solid',isDisabled?'disable-click':'']" v-if="paramsConf.length>1" @click="deleteParams(index)"></i>
                                        <i :class="['arsenal_icon','arsenalplus-solid',isDisabled?'disable-click':'']" @click="addParams" :disabled="isDisabled"></i>
                                    </div>
                                </div>
                            </div> 
                        </el-tab-pane>
<!--                        <el-tab-pane label="认证(Authorizatiion)" name="second">-->
<!--                            <div class="auth">-->
<!--                                <div class="username">-->
<!--                                    <span>用户名</span>-->
<!--                                    <el-input placeholder="请输入内容" v-model="authorizationConf.userName" :disabled="isDisabled"></el-input>-->
<!--                                </div>-->
<!--                                <div class="password">-->
<!--                                    <span>密码</span>-->
<!--                                    <el-input placeholder="请输入内容" v-model="authorizationConf.password" :disabled="isDisabled"></el-input>-->
<!--                                </div>-->
<!--                                <div class="token">-->
<!--                                    <span>Token</span>-->
<!--                                    <el-input placeholder="请输入内容" v-model="authorizationConf.token" :disabled="isDisabled"></el-input>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </el-tab-pane>-->
                        <el-tab-pane label="请求头(Headers)" name="third">
                            <div class="headers">
                                <div class="key">KEY</div>
                                <div class="value">VALUE</div>
                                <div class="desc">DESCRIPTION</div>
                            </div>
                            <div class="headers" v-for="(header,index) in headersConf" v-bind:key="index">
                                <div class="key"><el-input placeholder="请输入内容" v-model="header.key" :disabled="isDisabled"></el-input></div>
                                <div class="value"><el-input placeholder="请输入内容" v-model="header.value" :disabled="isDisabled"></el-input></div>
                                <div class="desc"><el-input placeholder="请输入内容" v-model="header.desc" :disabled="isDisabled"></el-input></div>
                                
                                <div class="operation">
                                    <i
                                            :class="['arsenal_icon','arsenaltrash-alt-solid',isDisabled?'disable-click':'']" v-if="headersConf.length>1" @click="deleteHeaders(index)" :disabled="isDisabled"></i>
                                    <i :class="['arsenal_icon','arsenalplus-solid',isDisabled?'disable-click':'']" @click=addHeaders :disabled="isDisabled"></i>
                                </div>
                            </div>
                        </el-tab-pane>
<!--                        <el-tab-pane label="消息体(Body)" name="fourth">-->
<!--                            <div class="body-content">-->
<!--                                <div class="title">-->
<!--                                    <el-radio-group v-model="bodyConf.type" @change="currentRadio">-->
<!--                                        <el-radio label="none" >无</el-radio>-->
<!--                                        <el-radio label="form-data">form-data</el-radio>-->
<!--                                        <el-radio label="x-www-form-urlencoded">x-www-form-urlencoded</el-radio>-->
<!--                                        <el-radio label="raw">raw</el-radio>-->
<!--                                        <el-radio label="binary">binary</el-radio>-->
<!--                                    </el-radio-group>-->
<!--                                </div>-->
<!--                                <div class="content">-->
<!--                                    <div v-show="isShowFormData" class="form-data">-->
<!--                                        <div class="params title">-->
<!--                                            <div class="params-name">参数名称</div>-->
<!--                                            <div class="params-value">默认值</div>-->
<!--                                            <div class="params-entity">关联实体</div>-->
<!--                                            <div class="params-value">描述</div>-->
<!--                                            <div class="params-delete"></div>-->
<!--                                        </div>-->
<!--                                        <div class="params" v-for="(bodyParam,index) in bodyConf.params" v-bind:key="index">-->
<!--                                            <div class="params-name"><el-input placeholder="请输入内容" v-model="bodyParam.key" :disabled="isDisabled"></el-input></div>-->
<!--                                            <div class="params-value"><el-input placeholder="请输入内容" v-model="bodyParam.value" :disabled="isDisabled"></el-input></div>-->
<!--                                            <div class="params-entity">-->
<!--                                                <div class="block">-->
<!--                                                    <el-cascader :options="entityOptions" :props="cascaderProps" v-model="bodyParam.entityName" :show-all-levels="false" @change="handleEntityChange" :disabled="isDisabled">-->
<!--                                                        <template slot-scope="{ node, data }">-->
<!--                                                            <span>{{ data.label }}</span>-->
<!--                                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>-->
<!--                                                        </template>-->
<!--                                                    </el-cascader>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <div class="params-value"><el-input placeholder="请输入内容" v-model="bodyParam.description" :disabled="isDisabled"></el-input></div>-->
<!--                                            <div class="params-delete">-->
<!--                                                <i :class="['arsenal_icon', 'arsenaltrash-alt-solid',isDisabled?'disable-click':'']" v-if="index!=0" @click="deleteBodyParams(index)"></i>-->
<!--                                                <i :class="['arsenal_icon', 'arsenalplus-solid',isDisabled?'disable-click':'']" @click="addBodyParams"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div v-show="isShowRaw" class="raw-form">-->
<!--                                        <el-input-->
<!--                                            type="textarea"-->
<!--                                            :rows="5"-->
<!--                                            placeholder="请输入内容"-->
<!--                                            v-model="bodyConf.content" :disabled="isDisabled">-->
<!--                                            </el-input>-->
<!--                                    </div>-->
<!--                                    <div v-show="isShowBinary" class="binary-form">-->
<!--                                        <el-upload-->
<!--                                            class="upload-binary"-->
<!--                                            :action="binaryUpload.actionUrl"-->
<!--                                            :file-list="binaryUpload.fileList"-->
<!--                                            name="file"-->
<!--                                            :on-success="onUploadSuccess"-->
<!--                                            :disabled="isDisabled">-->
<!--                                            <el-button size="small" type="primary" :disabled="isDisabled" :class="[isDisabled?'disable-click':'']">点击上传</el-button>-->
<!--                                            <div slot="tip" class="el-upload__tip">请选择上传文件，只能上传一个</div>-->
<!--                                            </el-upload>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </el-tab-pane>-->
                    </el-tabs>
                </div>
                <div class="show-request-item" @click="changRequestShowStatus">
                    <i v-show="!isShowRequest" class="el-icon-arrow-down"></i>
                    <i v-show="isShowRequest" class="el-icon-arrow-up"></i>
                </div>
                <div id="responseid" v-if="showSendResponse" class="response">
                    <div class="title">Response</div>
                    <div class="content">
                        <div class="response-content">
                            <json-viewer
                                :value="sendResponse"
                                :expand-depth=5
                                copyable
                                boxed
                                sort>
                            </json-viewer>
                        </div>
                        <div class="response-config">
                            <div class="title">
                                <div class="name">返回值名称</div>
                                <div class="multiple">是否为多值</div>
                                <div class="select">选择返回值</div>
                                <div class="operation"></div>
                            </div>
                            <div class="config-content">
                                <div class="content" v-for="(respConf,index) in responseConf.config" v-bind:key="index">
                                <div class="name"><el-input placeholder="请输入内容" v-model="respConf.name" :disabled="isDisabled"></el-input></div>
                                <div class="multiple"><el-checkbox v-model="respConf.multivalued" :disabled="isDisabled"></el-checkbox></div>
                                <div class="select"><el-input placeholder="请输入内容" v-model="respConf.value" :disabled="isDisabled"></el-input></div>
                                <div class="operation">
                                    <i
                                            :class="['arsenal_icon','arsenaltrash-alt-solid',isDisabled?'disable-click':'']" v-if="responseConf.config.length>1" @click="deleteResponseConfig(index)" :disabled="isDisabled"></i>
                                    <i :class="['arsenal_icon', 'arsenalplus-solid',isDisabled?'disable-click':'']" @click="addResponseConfig"></i>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="修改目录名称"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <el-input v-model="divNameinput" placeholder="请输入目录名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveReName">确 定</el-button>
            </span>
        </el-dialog>
    </div>
    
</template>
<script>
import Popup from "@/components/popup";
import VersionTip from "./../version/PversionTip.vue";
import pageTopHeader from '../../components/page-top-header.vue'
let id = 1000;
export default {
    data() {
        return {
            botVersion: 0,
            releaseIsDisabled: false,
            isRelease: false,
            nameConst:{
                defaultNewDirName:'新目录',
                defaultNewWebHookName:'新webhook',
                defaultFirstDirName:'全部'
            },
            tips:{
                deleteTip:'请先删除子目录或WebHook',
                deleteConfirm:'您确定要删除此节点吗？',
                deleteSuccess:'删除成功',
                paramTip:'webhook参数不正确，请检查',
                webhookNameBlankTip:'请输入WebHook名称',
                webhookNameRepeatTip:'WebHook名称重复',
                saveSuccess:'保存配置成功',
                releaseConfirm:'发布以后将不能再编辑该Webhook，确定要发布吗？',
                releaseSuccess:'发布成功',
                updateDirBlank:'目录名称不能为空',
                updateDirSuccess:'修改成功',
                sendSuccess:'连接成功'
            },
            initObj:{
                params:{
                    key:'',
                    value:'',
                    required:false,
                    entityId:'',
                    entityName:[]
                },
                headers:{
                    key:'',
                    value:'',
                    description:''
                },
                respConf:{
                    name:'',
                    value:'',
                    multivalued:false
                },
                sendResponse:{
                    code:'200',
                    msg:'success',
                    data:''
                }
            },
            activePageInfo: {
                headerName: "接口对接"
            },
            searchDirKeyword:'',
            activeTabName: "question",
            isOpenWebhookItem: false,
            activeName: 'first',
            isShowTabCount: true,
            paramsIsmust: true,
            data: [],
            isShowPopover: false,
            bodyRadio: 'none',
            isShowFormData: false,
            isShowWWWForm: false,
            isShowRaw:false,
            isShowBinary:false,
            webhookDirList: [],
            entityOptions:[],
            methodOptions:[
                {
                    name:'GET',
                    value:"GET"
                },
                {
                    name:"POST",
                    value:"POST"
                },
                {
                    name:'PUT',
                    value:"PUT"
                },
                {
                    name:"DELETE",
                    value:"DELETE"
                }
            ],
            blankOption:'',
            visible:false,
            webhookDetail:{},
            authorizationConf:{},
            paramsConf:[],
            paramType:'none',
            headersConf:[],
            bodyConf:{
                type:'none',
                params:[]
            },
            responseConf:{
                config:[]
            },
            binaryUpload:{
                fileList: [],
                actionUrl:''
            },
            sendResponse:{},
            isShowEmptyStatus: true,
            dialogVisible:false,
            divNameinput: '',
            currentDivData: null,
            currentDivNode: null,
            loading: true,
            cascaderProps:{
                value:'label'
            },
            isDisabled:false,
            currentPath:'',
            isShowRequest: true,
            showSendResponse: true
        };
    },
    computed: {
        isShow() {
        let mainId = localStorage.getItem('_mainId')
        return mainId == '90df4764122240de939331d372546c28' || mainId == '1419b09cb2ce11ea8f0900163e0964d5'
        }
    },
    components: {VersionTip, Popup,pageTopHeader},
    methods: {
        getCurrentUrl(){
            // this.currentPath = this.$route.path;
            console.log('path:',this.currentPath);
            if (this.RP_Visible('OPEN_WEBHOOK')) {
                this.currentPath = '/main/webhook';
            }else {
                if (this.RP_Visible('OPEN_FORM')) {
                    this.currentPath = '/main/fieldManage';
                    this.$router.replace('/main/fieldManage');
                }
            }

        },
        changeWebhookOpenStatus(){
            if(this.isOpenWebhookItem){
                this.isOpenWebhookItem = false;
            }else{
                this.isOpenWebhookItem = true;
            }
        },
        showPopover(id){
            var span1 = document.getElementById('popover'+id);
            span1.style.display = "block";
        },
        hiddenPopover(id){
            var span = document.getElementById('popover'+id);
            span.style.display = "none";
        },
        addParams(){
            this.paramsConf.push(this.clone(this.initObj.params));
        },
        addHeaders(){
            this.headersConf.push(this.clone(this.initObj.headers));
        },
        addBodyParams(){
            this.bodyConf.params.push(this.clone(this.initObj.headers));
        },
        addResponseConfig(){
            this.responseConf.config.push(this.clone(this.initObj.respConf));
        },
        deleteParams(index){
            this.paramsConf.splice(index,1)
        },
        deleteHeaders(index){
            this.headersConf.splice(index,1);
        },
        deleteBodyParams(index){
            this.bodyConf.params.splice(index, 1);
        },
        deleteResponseConfig(index){
            this.responseConf.config.splice(index,1);
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        handleEntityChange(value){
            console.log(value);
        },
        clearContent(){
            this.webhookDetail={};
            this.authorization={};
            this.paramsConf=[this.clone(this.initObj.params)];
            this.headersConf=[this.clone(this.initObj.headers)];
            this.bodyConf={
                type:'none',
                params:[this.clone(this.initObj.headers)],
            };
            this.responseConf={
                config:[this.clone(this.initObj.respConf)],
                
            };
            this.sendResponse=this.clone(this.initObj.sendResponse);
        },
        clone(value){
            return JSON.parse(JSON.stringify(value));
        },
        getEntityTree(){
            this.FetchGet(this.requestUrl.entity.getEntityTree).then(res => {
                if (res.code === "0") {
                    this.entityOptions = res.data;
                    console.log(this.entityOptions);
                } else {
                    this.$message.error(res.message);
                }
                
            });
        },
        appendFirstNode(){
            let objData= {
                name: this.nameConst.defaultFirstDirName,
                parentId: 0
            };
            this.FetchPost(this.requestUrl.webhook.addDir,objData)
                .then(res => {
                    if (res.code === "0") {
                        this.getWebhookDirList(); 
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        append(node,data) {
            console.log('data:',data);
            //add dir
            let objData= {
                name: this.nameConst.defaultNewDirName,
                parentId: data.id
            };
            console.log(objData);
            this.FetchPost(this.requestUrl.webhook.addDir,objData)
                .then(res => {
                    if (res.code === "0") {
                        // const newChild = { id:res.data, label: this.nameConst.defaultNewDirName, type: "dir", isLeafNode: true,count:0,children: [] };
                        // if (!data.children) {
                        //     this.$set(data, 'children', []);
                        // }
                        // data.children.push(newChild);
                        this.getWebhookDirList(); 
                    } else {
                        this.$message.error(res.message);
                    }
                    this.visible = false;
                });
        },
        updateDir(node,data){
            if(data.label==null || data.label.length==0){
                this.$message.error(this.tips.updateDirBlank);
                return;
            }
            let dirData= {
                id: data.id,
                name: data.label
            };
            this.FetchPost(this.requestUrl.webhook.addDir,dirData)
                .then(res => {
                    if (res.code === "0") {
                        this.$message.success(this.tips.updateDirSuccess);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.visible = false;
                });
        },
        addleaf(data){
            let webhookData= {
                name: this.nameConst.defaultNewWebHookName,
                dirId: data.id
            };
            this.FetchPost(this.requestUrl.webhook.webhookAdd,webhookData)
                .then(res => {
                    if (res.code === "0") {
                        // const newChild = { id:res.data, label: this.nameConst.defaultNewWebHookName, type: "api", isLeafNode: true,count:0,children: [] };
                        // if (!data.children) {
                        //     this.$set(data, 'children', []);
                        // }
                        // data.children.push(newChild);
                        this.getWebhookDirList();
                    } else {
                        this.$message.error(res.message);
                    }
                    this.visible = false;
                 });
        },
        reName(node, data){
            //console.log("data=====",data);
            //console.log("node=====",node);
            //this.visible=false;
            this.dialogVisible = true;
            this.currentDivData = data;
            this.currentDivNode = node;
            this.divNameinput = data.label;
            console.log("data=====",data);
            console.log("node=====",node);
        },
        saveReName(){
            let node = this.currentDivNode;
            let data = this.currentDivData;
            data.label = this.divNameinput;
            this.updateDir(node,data);
            this.dialogVisible = false;
        },
        copyWebhook(node, data){
            console.log(node.parent);
            console.log('copy id ==========',data.id);
            this.FetchPost(this.requestUrl.webhook.webhookCopy+'?wId='+data.id).then(res =>{
                console.log(res);
                if(res.code === '0'){
                    // const newChild = { id:res.data, label: data.label+"_副本", type: "api", isLeafNode: true,count:0,children: [] };
                    // node.parent.data.children.push(newChild);
                    this.getWebhookDirList();
                }else{
                    this.$message.error(res.message);
                }
                this.visible = false;
            });
        },
        remove(node, data) {
            if(data.children!=null&&data.children.length>0){
                this.$message.error(this.tips.deleteTip);
                this.visible = false;
                return;
            }
            this.$confirm(this.tips.deleteConfirm)
                .then(_ => {
                    let delUrl=this.requestUrl.webhook.deleteDir;
                    if(data.type=='api'){
                        delUrl=this.requestUrl.webhook.deleteWebhook;
                    }
                    this.FetchDelete(delUrl,data.id).then(res =>{
                        if(res.code === '0'){
                            // const parent = node.parent;
                            // const children = parent.data.children || parent.data;
                            // const index = children.findIndex(d => d.id === data.id);
                            // children.splice(index, 1);
                            this.$message.success(this.tips.deleteSuccess);
                            this.getWebhookDirList();
                        }else{
                            this.$message.error(res.message);
                        }
                        this.visible = false;
                    });
                });
        },
        currentRadio(value){
            console.log('e:'+value);
            if(value == 'form-data' || value == 'x-www-form-urlencoded'){
                this.isShowFormData = true;
                this.isShowRaw = false;
                this.isShowBinary = false;
            }else if(value == 'raw'){
                this.isShowFormData = false;
                this.isShowRaw = true;
                this.isShowBinary = false;
            }else if(value == 'binary'){
                this.isShowFormData = false;
                this.isShowRaw = false;
                this.isShowBinary = true;
            }else if(value == 'none'){
                this.isShowFormData = false;
                this.isShowRaw = false;
                this.isShowBinary = false;
            }
        },
        // 获取目录列表
        getWebhookDirList() {
            this.FetchGet(this.requestUrl.webhook.getWebhookDir+'?keyword='+this.searchDirKeyword).then(res => {
                if (res.code === "0") {
                    this.webhookDirList = res.data;
                    this.loading = false;
                    console.log(this.webhookDirList);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        showWebhookDetail(data){
            if(data.type=='api'){
                this.isShowEmptyStatus = false;
                if (!this.RP_Visible('OPEN_WEBHOOK_UPDATE')) {
                    this.isDisabled = true;
                } else {
                    this.isDisabled=false;
                }
                
                this.FetchGet(this.requestUrl.webhook.webhookDetail+data.id).then(res => {
                    if (res.code === "0") {
                        this.clearContent();
                        this.showSendResponse = true;
                        let webhookInfo = res.data;
                        if(webhookInfo!=null && webhookInfo.setting!=null){
                            let setting=webhookInfo.setting;
                            if(setting.params!=null&&setting.params.length>0){
                                this.paramsConf=webhookInfo.setting.params;
                            }
                            this.paramType  = webhookInfo.setting.paramType == null
                                ?'none':webhookInfo.setting.paramType;

                            if(setting.headers!=null&& setting.headers.length>0){
                                this.headersConf=webhookInfo.setting.headers;
                            }
                            if(setting.body!=null){
                                this.bodyConf=webhookInfo.setting.body;
                                if(this.bodyConf.params==null || this.bodyConf.params.length==0){
                                    this.bodyConf.params=[this.clone(this.initObj.headers)];
                                }
                                if(this.bodyConf.type == null){
                                    this.bodyConf.type='none';
                                }
                                if(this.bodyConf.fileItem!=null){
                                    console.log('==========',this.bodyConf.fileItem);
                                    this.binaryUpload.fileList=[this.bodyConf.fileItem];
                                }
                                this.currentRadio(this.bodyConf.type);
                            }
                            if(setting.authorization!=null){
                                this.authorizationConf=webhookInfo.setting.authorization;
                            }
                            if(setting.response!=null){
                                this.responseConf=webhookInfo.setting.response;
                                if(this.responseConf.config==null || this.responseConf.config.length==0){
                                    this.responseConf.config=[this.clone(this.initObj.respConf)];
                                }
                                if(this.responseConf.sendResponse!=null){
                                    this.sendResponse=this.responseConf.sendResponse;
                                }
                            }
                        }
                        this.getEntityTree();
                        //将webhook setting单独拿出去
                        webhookInfo.setting={};
                        this.webhookDetail=webhookInfo;
                        if (this.webhookDetail.method == '') {
                            this.webhookDetail.method = 'GET';
                        } 
                        console.log(webhookInfo.status);
                        if(webhookInfo.status===1){
                            this.isRelease = true;
                            this.isDisabled=true;
                            this.releaseIsDisabled = true;
                            
                        } else {
                            this.isRelease = false;
                            if (!this.RP_Visible('OPEN_WEBHOOK_RELEASE')) {
                                this.releaseIsDisabled = true;
                            } else {
                                this.releaseIsDisabled = false;
                            }
                        }
                        
                        console.log(this.webhookDetail);
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        sendRequest(){
            let webhookInfo=this.getWebhookInfo();
            console.log(webhookInfo);
            this.FetchPost(this.requestUrl.webhook.webhookSend,webhookInfo)
                .then(res => {
                    if (res.code === "0") {
                        console.log(res.data);
                        this.sendResponse=res.data;
                        this.showSendResponse = true;
                        this.$message.success(this.tips.sendSuccess);
                    } else {
                        this.$message.error(res.message);
                    }
            });
        },
        getWebhookInfo(){
            let webhookInfo=this.webhookDetail;
            webhookInfo.setting={};
            webhookInfo.setting.params=this.paramsConf;
            webhookInfo.setting.paramType=this.paramType;
            webhookInfo.setting.headers=this.headersConf;
            webhookInfo.setting.body=this.bodyConf;
            webhookInfo.setting.authorization=this.authorizationConf;
            this.responseConf.response=this.webhookReponse;
            this.responseConf.sendResponse=this.sendResponse;
            webhookInfo.setting.response=this.responseConf;
            return webhookInfo;
        },
        onUploadSuccess(response, file, fileList){
            console.log(response);
            console.log(file);
            console.log(fileList);
            this.bodyConf.fileUrl=response.data;
            this.bodyConf.fileItem=file;
        },
        saveConf(callback){
            let webhookInfo=this.getWebhookInfo();
            console.log(webhookInfo);
            if(this.checkWebhookName(webhookInfo)){
                this.FetchGet(this.requestUrl.webhook.webhookNameCheck+'?id='+webhookInfo.id+'&name='+webhookInfo.name).then(res => {
                    if (res.code === "0") {
                        if(res.data!=null && res.data){
                            this.$message.error(this.tips.webhookNameRepeatTip);
                        }else{
                            this.FetchPost(this.requestUrl.webhook.webhookAdd,webhookInfo)
                            .then(res => {
                                if (res.code === "0") {
                                    this.$message.success(this.tips.saveSuccess);
                                    this.getWebhookDirList();
                                    this.showWebhookDetail({type:'api',id:webhookInfo.id});
                                    if (callback){
                                        callback();
                                    }
                                } else {
                                    this.$message.error(res.message);
                                }
                            });
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        release(){
            this.saveConf(()=>{
                let webhookInfo=this.getWebhookInfo();
                if(this.checkParam(webhookInfo)){
                    this.$confirm(this.tips.releaseConfirm)
                        .then(_ => {
                            this.FetchPut(this.requestUrl.webhook.webhookRelease, webhookInfo.id,'status=1').then(
                                res => {
                                    if (res.code === "0") {
                                        this.$message.success(this.tips.releaseSuccess);
                                        this.isDisabled = true;
                                        this.getWebhookDirList();
                                    } else {
                                        this.$message.error(res.message);
                                    }
                                }
                            );
                        })
                }
            });

        },
        checkWebhookName(webhookInfo){
            if(webhookInfo.name==null || webhookInfo.name==''){
                this.$message.error(this.tips.webhookNameBlankTip);
                return false;
            }
            return true;
        },
        checkParam(webhookInfo){
            if(webhookInfo.name==null || webhookInfo.name=='' 
                || webhookInfo.url==null || webhookInfo.url==''
                || webhookInfo.method==null || webhookInfo.method==''){
                this.$message.error(this.tips.paramTip);
                return false;
            }
            return true;
        },
        changRequestShowStatus(){
            if(this.isShowRequest){
                this.isShowRequest = false;
                let bodyHeight = document.body.clientHeight;
                let div = document.getElementById('responseid');
                div.style.height = bodyHeight-250 + 'px';
            }
            else{
                this.isShowRequest = true;
            }
        },
    },
    mounted() {
        this.botVersion = localStorage.getItem('_bot_version');
        this.getWebhookDirList();
        this.paramsConf.push(this.clone(this.initObj.params));
        this.headersConf.push(this.clone(this.initObj.headers));
        this.bodyConf.params.push(this.clone(this.initObj.headers));
        this.responseConf.config.push(this.clone(this.initObj.respConf));
        this.binaryUpload.actionUrl=this.GetFullUrl(this.requestUrl.webhook.uploadFile);
        this.sendResponse=this.clone(this.initObj.sendResponse);
        this.getCurrentUrl();

    }
};
</script>
<style lang="less">
@import "./../../assets/less/Webhook/Webhook.less";
.el-tree-node__content{
    height: 45px !important;
    line-height: 45px !important;
    align-items: center;
    .eltree{
        display: none;
    }
}
.el-popover{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    .el-button{
        text-align: left;
        margin-left: 10px;
    }
}
#webhook {
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .label-name{
        margin-left: 6px !important;
    }
  }

.subMenu{
    width:150px;
    background-color: #ffffff;
    border-right: 6px solid #f2f6fc;
    text-align: left;
}
/* .is-current{
    background-color: #333333 !important;
} */
// .binary-form{
//     float:left;
// }
.el-menu-item-group__title{
    padding: 0!important;
}
.el-submenu .el-menu-item{
    height: 48px;
    line-height: 48px;
    width: 150px;
    padding: 0 45px;
    min-width: 150px;
}
.activeClass{
    border-right: 2px solid #409EFF;
}
// .el-submenu__title{
//     padding: 0 10px !important;
// }
}



</style>