<template>
    <div>
        <div class="module-result-content-field">
            <div class="result-content-field">
                <el-table
                        :data="formList"
                        height="calc(100vh - 150px)"
                        class="tableClass"
                        width="100%"
                        :header-cell-style="{fontSize:'14px', fontWeight:'500'}"
                        :row-style="{fontSize:'14px',cursor:'pointer',height:'72px',lineHeight:'72px',padding:'0!important'}"
                        :row-class-name="getRowClass"
                        :cell-style="{padding:'0!important'}"
                >
                    <el-table-column
                            type="index"
                            :index="indexMethod"
                            label="序号"
                            width="80"
                            style="margin-left: 50px;"
                            align="center"
                            header-align="center"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="表单名称"
                            align="left"
                            header-align="left"
                            width="250"
                            style="text-align: left"
                    >
                        <template scope="scope">
                            <div class="fieldinfoName" style="line-height: 64px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap">
                                <span style="margin-right: 4px" v-if="scope.row.release"><el-tag size="mini">已发布</el-tag></span>
                                <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="scope.row.name"
                                        placement="top-start"
                                        :disabled="isShowTooltip"
                                >
                                    <span :ref="scope.row.name"  @mouseover="onMouseOver(scope.row.name)">{{scope.row.name}}</span>
                                </el-tooltip>

                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="scope"
                            label="适用机器人"
                            align="left"
                            header-align="left"
                            width="250"
                            style="text-align: left"
                    >
                        <template scope="scope">
                            <div v-if="scope.row.scope.scope == 1">{{formatBotsName(scope.row.scope.bots)}}</div>
                            <div v-if="scope.row.scope.scope == 0">全部机器人</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="describe"
                            label="表单描述"
                            align="left"
                            header-align="left"
                            style="text-align: left"
                            show-overflow-tooltip
                    >
                        <template scope="scope">
                            <div class="fieldinfoDes" v-if="scope.row.description!==''">{{scope.row.description}}</div>
                            <div class="fieldinfoDes" v-if="scope.row.description===''||scope.row.description===null">--</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="110"
                            align="center"
                    >
                        <template scope="scope">

                            <div class="fieldOpt" style="display: flex;justify-content: space-around">
                                <span style="font-size: 20px;color: #366AFF;font-weight: 600" @click="reviseForm(scope.row.id,scope.row.release,scope.row.editable)" v-if="(scope.row.release===false && (RP_Visible('OPEN_FORM_UPDATE') || RP_Visible('OPEN_FORM_RELEASE')) && scope.row.editable)"><i class="el-icon-edit"></i></span>
                                <span style="font-size: 20px;color: #366AFF;font-weight: 600" @click="reviseForm(scope.row.id,scope.row.release,scope.row.editable)" v-if="(scope.row.release && RP_Visible('OPEN_FORM_VIEW')) && scope.row.editable"><i class="el-icon-view" ></i></span>
                                <span style="font-size: 20px;color: #366AFF;font-weight: 600" @click="reviseForm(scope.row.id,scope.row.release,scope.row.editable)" v-if="!scope.row.editable"><i class="el-icon-view" ></i></span>
                                <span style="font-size: 20px;color: #366AFF;font-weight: 600" @click="reviseForm(scope.row.id,scope.row.release,scope.row.editable)" v-if="RP_Visible('OPEN_FORM_VIEW') && !RP_Visible('OPEN_FORM_UPDATE') && !RP_Visible('OPEN_FORM_RELEASE') && scope.row.release===false && scope.row.editable"><i class="el-icon-view" ></i></span>
                                <span style="font-size: 20px;color: red;font-weight: 600" @click="deleteOpenDialog(scope.row.id)" v-if="RP_Visible('OPEN_FORM_DELETE') && scope.row.editable"><i class="el-icon-delete"></i></span>
                                <span style="font-size: 20px;color: #366AFF;font-weight: 600" v-if="RP_Visible('OPEN_FORM_ADD')"><i class="el-icon-copy-document" @click="copyForm(scope.row.id)" title="复制"></i></span>
                                <!--<el-button type="primary" icon="el-icon-edit" circle @click="reviseForm(scope.row.id)" v-if="scope.row.id===1"></el-button>
                                <el-button type="primary" icon="el-icon-view" circle v-if="scope.row.id!==1"></el-button>

                                <el-button type="danger" icon="el-icon-delete" circle @click="deleteOpenDialog(scope.row.id)"></el-button>
                                <el-button type="primary" icon="el-icon-copy-document" circle></el-button>-->

                            </div>

                        </template>
                    </el-table-column>

                </el-table>

            </div>

        </div>

        <el-dialog
                title="删除表单"
                :visible.sync="dialogVisible"
                custom-class="formDialogClass"
                style="text-align: left"
                width="500px"
                >
            <span>删除将导致对话流程不可用，请谨慎操作！</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="danger" @click="deleteForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "formManageItem",
        data(){
            return{

                dialogVisible:false,
                isShowTooltip:false,
                formId:0
            }
        },
      props:{
        formList:{
          type:Array,
          default(){
            return []
          }
        }
      },
      mounted() {

      },
      methods:{
          formatBotsName(arr){
              let bots = [];
              if (arr != null && arr != undefined) {
                  arr.forEach(bot => {
                      bots.push(bot.name);
                  });
              }
              let botStr = bots.join("、");
              botStr = botStr.length > 50? botStr + "......": botStr;
              return botStr;
          },
            onMouseOver(str){
                let parentWidth = this.$refs[str].parentNode.offsetWidth;
                let contentWidth = this.$refs[str].offsetWidth;
                // 判断是否开启tooltip功能
                if (contentWidth+30 > parentWidth) {
                    this.isShowTooltip = false;
                } else {
                    this.isShowTooltip = true;
                }
            },
            getRowClass(){
                return 'formRowStyle'
            },
            indexMethod(index){
                return index+1
            },
            reviseForm(id,release,editable){
                this.$router.push({
                    path:'addForm',
                    query:{
                        id:id,
                        release:release,
                        editable:editable
                    }
                })
            },
            deleteOpenDialog(id){
              this.formId=id
                this.dialogVisible=true

            },
            copyForm(id){
              this.FetchPost('/api/form/copy/'+id).then(res=>{
                if(res.code==='0'){
                    this.$message({
                        type:'success',
                        message:'复制成功!'
                    })
                    this.$emit('copyForm')
                }
              })

            },
          deleteForm(){
              this.FetchDelete('/api/form/',this.formId).then(res=>{
                if(res.code==='0')
                {
                  this.$message.success('删除表单成功')
                  this.$emit('deleteForm')
                }
              })
              this.dialogVisible=false
          },
        }
    }
</script>

<style scoped lang="less">
    .el-form-item__content{
        text-align: left;
    }
    .fieldRowClass{
        font-family: "Arial Normal", "Arial", sans-serif;
    }
    .module-result-content-field {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
    .result-content-field {
        flex: 1;
        position: relative;
    .el-table {

        position: absolute;
        overflow: hidden;

    }
    }
    }
    .formDialogClass{
        min-width: 200px;
        width: 400px;
    }
    .el-dialog__body{
        text-align: left;
    }
    .formRowStyle{
        padding: 0!important;
        font-family: "Arial Normal", "Arial", sans-serif;
        line-height: 72px;
    }

</style>