<template>
    <div id="formManage">
        <!-- <div class="content-header" style="width:100%">
            <div class="leftInfo">
                <span class="headerName">{{activePageInfo.headerName}}</span>
                <div class="new-add-button"><el-button type="primary" v-if="botVersion != 2 && RP_Visible('OPEN_FORM_FIELD_ADD')" @click="addFieldClick" style="margin-right: 24px">新增字段</el-button></div>

            </div>
        </div> -->
         <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenaljicheng"></i>
                <span class="page-name">接口对接</span>
            </div>
            <div slot="top-header-right" class="top-header-right">
                <div class="new-add-button"><el-button type="primary" v-if="botVersion != 2 && RP_Visible('OPEN_FORM_FIELD_ADD')" @click="addFieldClick" style="margin-right: 24px">新增字段</el-button></div>
            </div>
        </page-top-header>
        <div class="formmanage-content">
            <div class="left-nav-menu">
                <el-menu
                    active-text-color="#366AFF"
                    :default-openeds="['/main/form']"
                    :default-active = "currentPath"
                    router
                    class="el-menu-vertical-demo">
                    <el-menu-item index="/main/webhook" style="padding-left: 20px" v-if="RP_Visible('OPEN_WEBHOOK')" :class="{activeClass:this.$route.path==='/main/webhook'}">
                        <i class="el-icon-connection"></i>
                        <span slot="title">Webhook</span>
                    </el-menu-item>
                    <el-submenu index="/main/form">
                        <template slot="title">
                        <i class="el-icon-set-up"></i>
                        <span>自定义表单</span>
                        </template>
                        <el-menu-item-group>
                        <el-menu-item index="/main/fieldManage" :class="{activeClass:this.$route.path==='/main/fieldManage'}">字段管理</el-menu-item>
                        <el-menu-item index="/main/formManage" :class="{activeClass:this.$route.path==='/main/formManage'}">表单管理</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item
                        index="/main/epidemic"
                        v-if="isShow"
                        :class="{ activeClass: this.$route.path === '/main/epidemic', epidemic_view: true }"
                        >
                        <template slot="title">
                            <i class="arsenal_icon" style="font-size:16px;margin-right:10px">&#xed6d;</i>
                            <span>疫区管理</span>
                        </template>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="right-content">
                <!--<div class="list-header">
                    <div class="search"></div>
                    </div>-->
                <div v-if="botVersion == 2">
                    <VersionTip tipIndex="4"/>
                </div>
                <div v-else class="list-content">

                        <field-manage-item
                            :fieldList="fieldList"
                            @modifyField="modifyField"
                            @deleteField="deleteField"
                            @reviseField="reviseField"
                        >

                        </field-manage-item>

                    <div style="position: absolute;bottom: 10px;right: 8px;left: 0;text-align: right;">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page=fieldPage
                                :page-sizes="[10, 20, 30, 40]"
                                :page-size=fieldCurrentSize
                                layout="total, sizes, prev, pager, next, jumper"
                                :total=fieldTotal>
                        </el-pagination>
                    </div>


                </div>

            </div>
        </div>
        <el-dialog
                title="新增字段"
                :visible.sync="dialogFormVisible"
                custom-class="fieldDialog"
                :close-on-click-modal="false"
        >
            <el-form :model="form"  :label-position="labelPosition" >
                <el-form-item label="字段名称" :label-width="formLabelWidth"  style="text-align: left">
                <el-input v-model="fieldName" autocomplete="off"  placeholder="请输入字段名称"  maxlength="8" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="字段描述" :label-width="formLabelWidth" style="text-align: left">
                <el-input v-model="fieldDescription" autocomplete="off" placeholder="请输入字段描述"></el-input>
                </el-form-item>
                <el-form-item label="字段类型" :label-width="formLabelWidth" style="text-align: left">
                <el-select
                        v-model="fieldType.value"
                        placeholder="请选择字段类型"
                        @change="selectType"
                        style="width: 100%"
                >
                    <el-option
                            v-for="(items,index) in fieldTypes"
                            :key=index
                            :label=items.label
                            :value=items.value>
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item>
                    <div class="singleText" v-show="show===0">
                        <div class="singleTextInput">
                            <el-input v-model="singleText" autocomplete="off"  placeholder="示例:我的名字是什么"  size="small"></el-input>
                        </div>
                        <div class="singleTextText">
                            <div class="text" style="margin-right: 8px">可输入少量的文本</div>
                        </div>
                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>
                    <div class="multiLineText" v-show="show===1">
                        <div class="mulInput">
                            <el-input
                                    v-model="MuliteText"
                                    placeholder="可以输入大量文本"
                                    type="textarea"
                                    :rows="2"
                                    size="mini"
                            >
                            </el-input>
                        </div>
                        <div class="text" style="margin-right: 8px">可输入大量的文本</div>
                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>
                    <div class="block" v-show="show===2">

                        <div class="dataPicker">
                            <el-date-picker
                                    class="data"
                                    v-model="datePicker"
                                    type="date"
                                    placeholder="选择日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%"

                            >
                            </el-date-picker>
                        </div>
                        <div class="dataPickerText" style="margin-right: 8px">日期,&nbsp;如:{{datePicker}}</div>


                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>

                    <div class="timeSelect" v-show="show===3">

                        <div class="timePicker">
                            <el-time-picker
                                    v-model="time1"
                                    placeholder="任意时间点"
                                    format="HH:mm:ss"
                                    value-format="HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-time-picker>
                        </div>
                        <div class="timePickerText" style="margin-right: 8px">时间,&nbsp;如:{{time1}}</div>
                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>
                        <!-- 这里已经很乱了，可以重构下 -->
                    <div class="fieldSelect" v-show="show===4 ||  fieldType.value == 'OPTION'">
                        <div class="selectMore">
                            <el-select v-model="select2" placeholder="请选择" style="width: 100%">
                                <el-option label="选项1" value="选项1"></el-option>
                                <el-option label="选项2" value="选项2"></el-option>
                            </el-select>
                        </div>


                        <div class="selectMoreText" style="margin-right: 8px">多个选项中选择一个</div>

                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>

                    <div class="fieldManRadio" v-show="show===5">

                        <div class="radioCheck" style="display: flex">
                            <div style="flex: 0.33 0.33 0">
                                <el-radio v-model="radios" label="1">选项1</el-radio>
                            </div>
                            <div style="flex: 0.33 0.33 0">
                                <el-radio v-model="radios" label="2">选项2</el-radio>
                            </div>
                            <div style="flex: 0.33 0.33 0">
                                <el-radio v-model="radios" label="3">选项3</el-radio>
                            </div>
                        </div>

                        <div class="radioCheckText" style="margin-right: 8px">多个选项中选择一个</div>

                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>

                    <div class="fieldCheckbox" v-show="show===6">

                        <div class="checkSelect">
                            <el-checkbox-group v-model="checkSelect" style="width: 100%;display: flex">
                                <div style="flex: 0.33 0.33 0">
                                    <el-checkbox label="选项1"></el-checkbox>
                                </div>
                                <div style="flex: 0.33 0.33 0">
                                    <el-checkbox label="选项2"></el-checkbox>
                                </div>
                                <div style="flex: 0.33 0.33 0">
                                    <el-checkbox label="选项3"></el-checkbox>
                                </div>
                            </el-checkbox-group>
                        </div>
                        <div class="checkSeleckText" style="margin-right: 8px">可以选择多个选项</div>
                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>

                    <div class="cascade" v-show="show===7">
                        <div class="cascadeSelectProvince">
                            <el-select v-model="selectProvince" placeholder="请选择">
                                <el-option label="北京市" value="北京市"></el-option>
                                <el-option label="上海市" value="上海市"></el-option>
                            </el-select>
                        </div>
                        <div class="cascadeSelectCity">
                            <el-select v-model="selectCity" placeholder="请选择">
                                <el-option label="朝阳区" value="朝阳区"></el-option>
                                <el-option label="海淀区" value="海淀区"></el-option>
                            </el-select>
                        </div>
                        <div class="checkSeleckText">级联,多级选项</div>
                        <img src="../../assets/images/u2434.png" class="fieldImg"/>
                    </div>
                    <div class="dateTimeRange" v-show="show===8">
                    <div style="flex: 0.8 0.8 0;margin: 0 10px">
                      <el-date-picker
                          v-model="dateTimeValue"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          style="width: 100%"
                      >
                      </el-date-picker>
                    </div>
                    <div  style="margin-right: 8px; flex: 0.2 0.2 0;text-align: right;white-space: nowrap;">选择日期时间范围</div>
                    <img src="../../assets/images/u2434.png" class="fieldImg"/>
                  </div>
                    <div class="timeRange" v-show="show === 9">
                     <div style="flex: 0.8 0.8 0;margin: 0 10px">
                       <el-time-picker
                           is-range
                           v-model="timeValue"
                           range-separator="至"
                           start-placeholder="开始时间"
                           end-placeholder="结束时间"
                           placeholder="选择时间范围"
                           style="width: 100%"
                           arrow-control
                       >
                       </el-time-picker>
                     </div>
                     <div  style="margin-right: 8px; flex: 0.2 0.2 0;text-align: right;white-space: nowrap;">选择时间范围</div>
                     <img src="../../assets/images/u2434.png" class="fieldImg"/>
                   </div>
                    <div class="dateRange" v-show="show === 10">
                    <div style="flex: 0.8 0.8 0;margin: 0 10px">
                      <el-date-picker
                          v-model="dateValue"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          style="width: 100%"
                      >
                      </el-date-picker>
                    </div>
                    <div  style="margin-right: 8px; flex: 0.2 0.2 0;text-align: right;white-space: nowrap;">选择日期范围</div>
                    <img src="../../assets/images/u2434.png" class="fieldImg"/>
                  </div>
                    <div v-show="show === 'FILE'">
                        <div  class="fileClass">
                            <div style="margin: 0 10px">
                                <el-upload
                                        class="upload-demo"
                                        action="https://jsonplaceholder.typicode.com/posts/"
                                      >
                                    <div class="upload--picture-card">
                                        <i class="el-icon-plus"></i>
                                    </div>

                                </el-upload>

                            </div>
                            <div  style="margin-right: 8px;text-align: right;white-space: nowrap;">支持上传图片</div>
                            <img src="../../assets/images/u2434.png" class="fieldImg"/>
                        </div>
                       <!-- <div class="fileTypeClass">
                            <span style="margin-right: 12px">允许上传:</span>
                            <el-checkbox-group v-model="fileTypeCheck" @change="changeClick">
                                <el-checkbox  v-for="item in fileTypeList" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
                            </el-checkbox-group>
                        </div>-->
                        <div class="quantityLimit">
                            <span style="margin-right: 12px">数量限制:</span>
                            <el-radio-group v-model="fileLimit" style="display: flex;align-items: center">
                                <el-radio label="one">单个</el-radio>
                                <el-radio label="more">多个</el-radio>
                            </el-radio-group>
                            <div v-if="fileLimit === 'more'" style="display: flex;justify-content: flex-end">
                                <span style="margin-right: 10px">数量上限:</span>
                                <el-input style="width: 40%" type="number" min="1" v-model="limitNum" placeholder="请输入数量的最大上限"></el-input>个
                            </div>
                        </div>
                  </div>

                    <div class="options"  v-show="show===4">
                        <div class="optionsText" style="text-align: left"><span class="optionsTab">*</span>选项设置</div>
                        <div class="optionsHead">

                            <div class="optionsSequence">顺序</div>

                            <div class="optionsName">选项名称</div>
                            <div class="optionsValue">选项值
                              <el-popover
                                  placement="top-start"
                                  width="200"
                                  trigger="hover"
                                  content="传递给WebHook时的值和选项的名称不一致时，可填写该字段">
                                <i class="el-icon-warning" slot="reference"></i>
                              </el-popover>

                            </div>

                            <div class="optionsIsDefalut">是否默认值</div>

                            <div class="optionsIsoperation">操作</div>
                        </div>
                        <div class="optionSetInfo" id="radioOption">
                            <option-set v-for="(item,index) in radioOptions"
                                        :key="index"
                                        :index="index+1"
                                        :index2="index"
                                        @delClick="delClick"
                                        :content="item"
                                        :radioOptions="radioOptions"
                                        @radioChange="radioChange"
                                        @resortOptionSet="resortOptionSet"
                                        @mouseUpClick="mouseUpClick"
                            >
                            </option-set>
                        </div>
                        <div class="addOption" @click="addOptions">
                            <span style="cursor: pointer"><i class="el-icon-circle-plus-outline"></i>添加选项</span>
                        </div>
                    </div>
                    <div class="options"  v-show="show===5">
                        <div class="optionsText" style="text-align: left"><span class="optionsTab">*</span>选项设置</div>
                        <div class="optionsHead">

                            <div class="optionsSequence">顺序</div>

                            <div class="optionsName">选项名称</div>
                          <div class="optionsValue">选项值
                            <el-popover
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                content="传递给WebHook时的值和选项的名称不一致时，可填写该字段">
                              <i class="el-icon-warning" slot="reference"></i>
                            </el-popover>
                          </div>

                            <div class="optionsIsDefalut">是否默认值</div>

                            <div class="optionsIsoperation">操作</div>

                            <!-- <img src="../../assets/images/fieldmanage/u2434.png" class="img"/>-->
                        </div>
                        <div id="radioOption2">
                            <option-set v-for="(item,index) in radioOptions" :key="index"
                                        :index="index+1"
                                        :index2="index"
                                        @delClick="delClick"
                                        :content="item"
                                        :radioOptions="radioOptions"
                                        @radioChange="radioChange"
                                        @resortOptionSet="resortOptionSet"
                                        @mouseUpClick="mouseUpClick"

                            >
                            </option-set>
                        </div>
                        <div class="addOption" @click="addOptions">
                            <span style="cursor: pointer"><i class="el-icon-circle-plus-outline"></i>添加选项</span>
                        </div>
                    </div>
                    <div class="options"  v-show="show===6">
                        <div class="optionsText" style="text-align: left"><span class="optionsTab">*</span>选项设置</div>
                        <div class="optionsHead">

                            <div class="optionsSequence">顺序</div>

                            <div class="optionsName">选项名称</div>
                          <div class="optionsValue">选项值
                            <el-popover
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                content="传递给WebHook时的值和选项的名称不一致时，可填写该字段">
                              <i class="el-icon-warning" slot="reference"></i>
                            </el-popover>
                          </div>

                            <div class="optionsIsDefalut">是否默认值</div>

                            <div class="optionsIsoperation">操作</div>

                            <!-- <img src="../../assets/images/fieldmanage/u2434.png" class="img"/>-->
                        </div>
                        <div id="radioOption3">
                            <option-set v-for="(item,index) in radioOptions" :key="index"
                                        :index="index+1"
                                        :index2="index"
                                        @delClick="delClick"
                                        :content="item"
                                        :radioOptions="radioOptions"
                                        @radioChange="radioChange"
                                        @resortOptionSet="resortOptionSet"
                                        @mouseUpClick="mouseUpClick"

                            >
                            </option-set>
                        </div>


                        <div class="addOption" @click="addOptions">
                            <span style="cursor: pointer"><i class="el-icon-circle-plus-outline"></i>添加选项</span>
                        </div>
                    </div>

                    <div class="cascadeOption" v-if="show===7">

                        <div  v-if="addCascade1===1" style="height: 72px">
                          <div class="cascadeOptionText" style="text-align: left">选项设置</div>
                            <div style="display: flex;">
                                <el-select
                                        style="flex:1 1 0"
                                        v-model="cascadeOptionSelect1"
                                        placeholder="点击添加下拉选项"
                                        @change="selectOption"
                                >
                                    <div
                                            v-for="(items,index) in optionValue"
                                            :key="index"
                                            style="display: flex;width: 100%"
                                    >
                                        <el-option

                                                :label="items.label"
                                                :value="items.value"
                                                style="flex: 1 1 0;line-height: 36px;"
                                                v-if="rivese1!==index"
                                        >
                                            <div >{{items.value}}</div>


                                        </el-option>
                                        <el-option
                                                label=""
                                                value=""
                                                style="line-height: 1px;margin: 0;padding: 0;"
                                                v-if="optionValue.length===1"
                                        >
                                        </el-option>
                                        <div v-if="rivese1===index" style="line-height: 36px;display:flex;flex: 1 1 0">
                                            <div style="flex: 0.8 0.8 0;height: 56px">
                                                <el-input v-model="riveseName1" placeholder="请输入选项名称"></el-input>
                                            </div>
                                            <div style="flex: 0.2 0.2 0;text-align: right">
                                                <span style="margin-right: 8px;margin-left: 8px;color: red;font-size: 12px;cursor: pointer" @click="rivese1Option">确认</span>
                                                <span style="font-size:12px;cursor: pointer" @click="cacleRivese1">取消</span>
                                            </div>

                                        </div>
                                        <div style="width:100px;text-align: right;line-height: 36px;margin-right: 8px;cursor: pointer;">
                                            <img src="../../assets/images/up.png" style="width: 16px;height: 16px;margin-right: 5px"  v-show="index > 0"  @click="upOption1(index)">
                                            <img src="../../assets/images/down.png" style="width: 16px;height: 16px;margin-right: 5px"  v-show="index<optionValue.length-1" @click="downOption1(index)">
                                            <img src="../../assets/images/rivese.png" style="width: 16px;height: 16px;margin-right: 5px" @click="riveseOption1(index,items.value)">
                                            <img src="../../assets/images/delred.png" style="width: 16px;height: 16px" @click="delOption1(index,items.value)">

                                        </div>
                                    </div>


                                    <el-option
                                            key="1000000"
                                            value=""
                                            label=""
                                            style="height: 1px"
                                            v-if="optionValue.length ===0"
                                    >
                                    </el-option>
                                    <div  v-if="showInput1===0" style="text-align: center;font-size: 14px;color: #366AFF;cursor: pointer;line-height: 32px;" @click="inputShow1">添加选项</div>
                                    <div v-if="showInput1===1" style="display:flex;height: 64px;line-height: 64px;">
                                        <div style="flex: 0.8 0.8 0">
                                            <el-input v-model="optionName" placeholder="请输入选项名称"></el-input>
                                        </div>
                                        <div style="flex: 0.2 0.2 0;text-align: right;margin-right: 8px">
                                            <span style="margin-right: 8px;margin-left: 8px;color: red;font-size: 14px;cursor: pointer" @click="addCascadeOption">确认</span>
                                            <span style="font-size: 14px;cursor: pointer" @click="cacleInput1">取消</span>
                                        </div>

                                    </div>

                                </el-select>
                                <div style="line-height: 54px" @click="delCascadeOption1">
                                    <img  src="../../assets/images/delred.png" style="width: 24px;margin-left: 12px;">
                                </div>
                            </div>
                        </div>

                        <div v-if="addCascade===2" style="height: 72px">
                          <div class="cascadeOptionText" style="text-align: left">选项设置</div>
                            <div style="display: flex">
                                <el-select style="flex: 1 1 0" v-model="cascadeOptionSelect2"  placeholder="点击添加下拉选项" @change="selectOption2">
                                    <div  v-for="(items,index) in OptionChildren" :key="index" style="display: flex;width: 100%">
                                        <el-option

                                                :label="items.label"
                                                :value="items.value"
                                                v-if="rivese2!==index"
                                                style="flex: 1 1 0;line-height: 36px"
                                        >
                                            <div>{{items.value}}</div>

                                        </el-option>
                                        <el-option

                                                label=" "
                                                value=""
                                                style="line-height: 1px;margin: 0;padding: 0;"
                                                v-if="OptionChildren.length===1"
                                        >
                                        </el-option>
                                        <div v-if="rivese2===index" style="line-height: 36px;display:flex;flex: 1 1 0">
                                            <div style="flex: 0.8 0.8 0;height: 56px">
                                                <el-input v-model="riveseName2" placeholder="请输入选项名称"></el-input>
                                            </div>
                                            <div style="flex: 0.2 0.2 0;text-align: right">
                                                <span style="margin-right: 8px;margin-left: 8px;color: red;font-size: 12px;cursor: pointer" @click="resiveOption2">确认</span>
                                                <span style="font-size: 12px;cursor: pointer" @click="cacleRivese2">取消</span>
                                            </div>

                                        </div>
                                        <div style="width:100px;text-align: right;line-height: 36px;margin-right: 8px;cursor: pointer">
                                            <img src="../../assets/images/up.png" style="width: 16px;height: 16px;margin-right: 5px" v-show="index > 0"  @click="upOption2(index)">
                                            <img src="../../assets/images/down.png" style="width: 16px;height: 16px;margin-right: 5px"  v-show="index<OptionChildren.length-1" @click="downOption2(index)">
                                            <img src="../../assets/images/rivese.png" style="width: 16px;height: 16px;margin-right: 5px" @click="riveseOption2(index,items.value)">
                                            <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;" @click="delOption2(index,items.value)">

                                        </div>
                                    </div>

                                    <el-option
                                            key="1000000"
                                            value=""
                                            label=""
                                            style="height: 1px"
                                            v-if="OptionChildren.length ===0"
                                    >
                                    </el-option>
                                    <div  v-if="showInput2===0" style="text-align: center;font-size: 14px;color: #366AFF;cursor: pointer;line-height: 32px;margin-bottom: 12px" @click="inputShow2">添加选项</div>

                                    <div v-if="showInput2===1" style="display:flex;height: 64px;line-height: 64px;">
                                        <div style="flex: 0.8 0.8 0">
                                            <el-input v-model="optionName2" placeholder="请输入选项名称"></el-input>
                                        </div>
                                        <div style="flex: 0.2 0.2 0;text-align: right;margin-right: 8px">
                                            <span style="margin-right: 8px;margin-left: 8px;color: red;font-size: 14px;cursor: pointer" @click="addCascadeOption2">确认</span>
                                            <span style="font-size: 14px;cursor: pointer" @click="cacleInput2">取消</span>
                                        </div>

                                    </div>

                                </el-select>
                                <div style="line-height: 54px" @click="delCascadeOption2">
                                    <img  src="../../assets/images/delred.png" style="width: 24px;margin-left: 12px;">
                                </div>

                            </div>
                        </div>

                        <div style="height: 72px" v-if="addCascade2===3">
                          <div class="cascadeOptionText" style="text-align: left">选项设置</div>
                            <div style="display: flex">
                                <el-select style="flex: 1 1 0" v-model="cascadeOptionSelect3"  placeholder="点击添加下拉选项" >
                                    <div    v-for="(items,index) in OptionChildren2"
                                            :key="index"
                                            style="display: flex;width: 100%"
                                    >
                                        <el-option

                                                :label="items.label"
                                                :value="items.value"
                                                v-if="rivese3!==index"
                                                style="flex: 1 1 0;line-height: 36px"
                                        >
                                            <div >{{items.value}}</div>
                                        </el-option>
                                        <el-option

                                                label=" "
                                                value=""
                                                style="line-height: 1px;margin: 0;padding: 0;"
                                                v-if="OptionChildren2.length===1"
                                        >
                                        </el-option>
                                        <div v-if="rivese3===index" style="line-height: 36px;display:flex;flex: 1 1 0">
                                            <div style="flex: 0.8 0.8 0;height: 56px">
                                                <el-input v-model="riveseName3" placeholder="请输入选项名称"></el-input>
                                            </div>
                                            <div style="flex: 0.2 0.2 0;text-align: right">
                                                <span style="margin-right: 8px;margin-left: 8px;color: red;font-size: 12px;cursor: pointer" @click="rivese3Option">确认</span>
                                                <span style="font-size: 12px;cursor: pointer" @click="cacleRivese3">取消</span>
                                            </div>

                                        </div>
                                        <div style="width:100px;text-align: right;line-height: 36px;margin-right: 8px;cursor: pointer">
                                            <img src="../../assets/images/up.png" style="width: 16px;height: 16px;margin-right: 5px" v-show="index > 0" @click="upOption3(index)">
                                            <img src="../../assets/images/down.png" style="width: 16px;height: 16px;margin-right: 5px" v-show="index<OptionChildren2.length-1" @click="downOption3(index)">
                                            <img src="../../assets/images/rivese.png" style="width: 16px;height: 16px;margin-right: 5px" @click="riveseOption3(index,items.value)">
                                            <img src="../../assets/images/delred.png" style="width: 16px;height: 16px" @click="delOption3(index,items.value)">

                                        </div>
                                    </div>

                                    <el-option
                                            key="1000000"
                                            value=""
                                            label=""
                                            style="height: 1px"
                                            v-if="OptionChildren2.length===0"
                                    >
                                    </el-option>
                                    <div  v-if="showInput3===0" style="text-align: center;font-size: 14px;color: #366AFF;cursor: pointer;line-height: 32px;" @click="inputShow3">添加选项</div>

                                    <div v-if="showInput3===1" style="display:flex;height: 64px;line-height: 64px;">
                                        <div style="flex: 0.8 0.8 0">
                                            <el-input v-model="optionName3" placeholder="请输入选项名称"></el-input>
                                        </div>
                                        <div style="flex: 0.2 0.2 0;text-align: right;margin-right: 8px">
                                            <span style="margin-right: 8px;margin-left: 8px;color: red;font-size: 14px;cursor: pointer" @click="addCascadeOption3">确认</span>
                                            <span style="font-size: 14px;cursor: pointer" @click="cacleInput3">取消</span>
                                        </div>

                                    </div>

                                </el-select>
                                <div style="line-height: 54px" @click="delCascadeOption3">
                                    <img  src="../../assets/images/delred.png" style="width: 24px;margin-left: 12px;">
                                </div>

                            </div>
                        </div>
                        <div v-if="show===7&&addCascade2!==3" style="line-height: 48px; text-align: center;color: #366AFF;font-size: 14px" @click="addCascadePullDown" :plain="true" >+添加级联下拉</div>
                    </div>

                    <div v-show="show === 'EXPLANATION'">
                        <div  class="fileClass">
                            <div style="margin: 0 10px">
                                我是解释说明文案
                            </div>
                            <img src="../../assets/images/u2434.png" class="fieldImg"/>
                        </div>
                        <div>
                            <div style="margin-right: 12px;text-align: left">解释说明文案:</div>
                            <div>
                                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="descriptionText" placeholder="请输入解释说明文案"></el-input>
                            </div>
                        </div>
                    </div>

                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addFormField">保 存</el-button>
            </div>
        </el-dialog>


      <el-dialog title="删除子级"
                 :visible.sync="dialogFormVisibleDelete2"
                 :close-on-click-modal="false"
                 custom-class="deleteField"
                 width="500px"
      >
        <div class="fieldDialogDelText" style="text-align: left">该级存在子级选项，删除将会全部删除，是否删除</div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleDelete2 = false">取 消</el-button>
          <el-button type="danger" @click="deleteAllOption">删 除</el-button>
        </div>
      </el-dialog>
      <el-dialog title="删除子级" :close-on-click-modal="false" :visible.sync="dialogFormVisibleDelete" custom-class="deleteField"  width="500px">
        <div class="fieldDialogDelText" style="text-align: left">该级存在子级选项，删除将会全部删除，是否删除</div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleDelete = false">取 消</el-button>
          <el-button type="danger" @click="deleteOption()">删 除</el-button>
        </div>
      </el-dialog>

        <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip tipIndex="4"/>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="versionTipDialog = false"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
    import OptionSet from "./optionset/OptionSet";
    import Sortable from "sortablejs";
    import FieldManageItem from './fieldManageItem'
    import pageTopHeader from '../../components/page-top-header.vue'
    import Popup from "@/components/popup";
    import VersionTip from "./../version/PversionTip.vue";

export default {
    data(){
        return{
            botVersion: 0,

            descriptionText: "", // 解释说明文案
            dialogFormVisible: false,
            dialogFormVisible1:false,
            dialogFormVisibleDelete2:false,
          dialogFormVisibleDelete:false,
            activePageInfo: {
                headerName: "接口对接"
            },
            labelPosition:'top',
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
                },
            formLabelWidth: '120px',
            rules:{
                name: [
                    { required: true, message: '请输入字段名称', trigger: 'blur' }
                ],
            },
            currentPath:'',
            fieldList:[
            ],
            show:0,
          fieldType:{
            value:'INPUT',
            label:'单行文本'
          },
            fieldTypes:[
                {
                    label: '单行文本',
                    value: 'INPUT'
                },
                {
                    label: '多行文本',
                    value: 'TEXTAREA'
                },
                {

                    label: '下拉列表',
                    value: 'SELECT'
                },
                {

                    label: '级联选择',
                    value: 'CASCADER'
                },
                {

                    label: '单选框',
                    value: 'RADIO'
                },
                {

                    label: '复选框',
                    value: 'CHECKBOX'
                },
                {

                    label: '日期选择',
                    value: 'DATE_PICKER'
                },

                {

                    label: '时间选择',
                    value: 'TIME_PICKER'
                },
                {
                    label:'附件',
                    value:'FILE'
                },
                {
                    label:'解释说明',
                    value:'EXPLANATION'
                },
                {
                    label:'资产',
                    value:'OPTION'
                }
             /* {
                label: '日期范围',
                value: 'DATE_RANGE'
              },
              {
                label: '时间范围',
                value: 'TIME_RANGE'
              },
              {
                label: '日期和时间范围',
                value: 'DATETIME_RANGE'
              }*/


            ],
            selectProvince:'',
            selectCity:'',
            checkSelect:['选项1','选项2'],
            radios:'1',
            select2:'',
            time1: '12:40:15',
            datePicker:'2020-08-02',
            singleText:'',
            MuliteText:'',
            fieldName:'',
            fieldDescription:'',
            dateTimeValue:'',
            dateValue: [new Date(2020, 9, 10, 8, 40), new Date(2020, 10, 10, 9, 40)],
            timeValue: [new Date(2020, 9, 10, 10, 40), new Date(2020, 9, 10, 11, 40)],
            radioOptions:[{value:'',label:'',_default:'false'},{value:'',label:'',_default:'false'}],
            cascadeOptionSelect:[],
            showInput1:0,
            showInput2:0,
            showInput3:0,
            optionValue:[],
            cascadeOptionSelect1:'',
            cascadeOptionSelect2:'',
            cascadeOptionSelect3:'',
            addCascade:1,
            addCascade2:1,
            addCascade1:1,
            selectOption1:[],
            optionName:'',
            optionName2:'',
            optionName3:"",
            cascadeOptionChildren:"",
            OptionChildren:[],
            OptionChildren2:[],
            cascadeOptionChildren2:'',
            fristOption:1,
            secondOption:1,
            thirdOption:1,
            fristName:'',
            secondName:'',
            thirdName:'',
            num2:0,
            deleteOption1:1,
            deleteOption2:1,
            deleteOption2Index:'',
            deleteOptionIndex:'',
            rivese1:'',
            rivese2:'',
            rivese3:'',
            showOption1:'',
            showOption2:'',
            showOption3:'',
            riveseName1:'',
            riveseName2:'',
            riveseName3:'',
            riveseIndex1:'',
            riveseIndex2:'',
            riveseIndex3:'',
            radioOptionsTest:[],
            cascadeOptionSelect1Num:0,
            cascadeOptionSelect2Num:0,
            cascadeOptionSelect3Num:0,
          fieldPage:1,
          fieldCurrentSize:20,
          fieldTotal:0,
            fileTypeList:[
                {label:'图片',value:'PICTURE'},
                {label:'视频',value:'VIDEO'},
                {label:'音频',value:'AUDIO'},
            ],
            fileTypeCheck:['PICTURE'],  //允许上传的文件类型
            fileLimit:'one',
            limitNum:1

        }
    },
    components:{
        OptionSet,
        FieldManageItem,
        pageTopHeader,
        VersionTip,
        Popup
    },
  mounted(){
    this.getCurrentUrl();
    this.getField()
    this.botVersion = localStorage.getItem('_bot_version');
  },
    computed: {
        isShow() {
        let mainId = localStorage.getItem('_mainId')
        return mainId == '90df4764122240de939331d372546c28' || mainId == '1419b09cb2ce11ea8f0900163e0964d5'
        }
    },
    methods:{
        limitWebhook(){
            this.versionTipDialog = true;
        },
        handleSizeChange(value){

          this.fieldCurrentSize=value
          this.getField()
        },
        handleCurrentChange(value){
          this.fieldPage=value
          this.getField()
        },
        getCurrentUrl(){
            this.currentPath = this.$route.path;
            console.log('path:',this.currentPath);
        },
      //点击新增字段的按钮事件
      addFieldClick(){
          this.fieldName=''
          this.fieldDescription=''
          this.fieldType.value='INPUT'
          this.show=0
          this.dialogFormVisible = true
      },
      //获取字段
      getField(){
        this.FetchGet('/api/form/fields?page='+this.fieldPage+'&size='+this.fieldCurrentSize).then(res=>{
          this.fieldTotal=res.data.total
          this.fieldList=res.data.list
        })
      },
        //新增字段
      addFormField() {
           if(this.fieldName==='')
           {
               this.$message({
                   message:'请输入字段名称',
                   type:'error',
                   duration:1000
               })
             return
           }
          /* if(this.fileTypeCheck.length === 0)
           {
               this.$message({
                   message:'请至少选择一种文件类型',
                   type:'error',
                   duration:2000
               })
               return
           }*/
           if(this.fileLimit === 'more'&&this.limitNum ==='')
           {
               this.$message({
                   message:'请选择上传数量上限',
                   type:'error',
                   duration:2000
               })
               return
           }
           else {
             let extInfo
             let radioOptionsList=[]
             if(this.fieldType.value==='RADIO'||this.fieldType.value==='CHECKBOX'||this.fieldType.value==='SELECT')
             {
               this.radioOptions.forEach(item=>{
                 if(item.label!=='')
                 {
                   radioOptionsList.push(item)
                 }
               })
               extInfo={
                 option:radioOptionsList,
                 value:[],
                 WebHookParameter:[]
               }
                if (radioOptionsList.length===0){
                     this.$message({
                         message:'请至少填写一项选项设置',
                         type:'error',
                         duration:2000
                     })
                     return;
                 }
             } else if (this.fieldType.value==='OPTION') {
                extInfo={
                    option:[],
                    value:[],
                    WebHookParameter:[]
                }
             }
             else if(this.fieldType.value==='CASCADER'){
               this.optionValue.forEach(item=>{
                 if(item.children!==undefined)
                 {
                   if(item.children.length===0)
                     delete item.children
                   else {
                     item.children.forEach(items=>{
                       if(items.children!==undefined)
                       {
                         if(items.children.length===0)
                           delete items.children
                       }

                     })
                   }
                 }
               })
               extInfo={
                 cascade:this.optionValue,
                 value:[],
                 WebHookParameter:[]
               }
             }
             else if (this.fieldType.value==='FILE'){
                 extInfo={
                     fileLimit:this.fileLimit,
                     WebHookParameter:[]
                 }
                 if (this.fileLimit==='more'){
                     extInfo.limitNum=this.limitNum
                 }
             }
             else if (this.fieldType.value==='EXPLANATION'){
                 extInfo = {
                     descriptionText: ""
                 }
                 if (this.descriptionText.trim() === ''){
                     this.$message({
                         message:'解释说明文案不能为空！',
                         type:'error',
                         duration:2000
                     })
                     return;
                 } else {
                     extInfo.descriptionText = this.descriptionText.trim();
                 }
             }
             else
             {
               extInfo={
                 value:'',
                 WebHookParameter:[]
               }
             }
             let field={
               description: this.fieldDescription,
               extInfo:extInfo,
               name: this.fieldName,
               type: this.fieldType.value
             }
             console.log(field)

             this.FetchPost('/api/form/field',field).then(res=>{
                 console.log(res)
               if(res.code ==='0')
               {
                 this.$message.success('添加字段成功')
                 this.dialogFormVisible=false
                 this.getField()
               }
             })
           }
        },
      modifyField(){
          this.getField()
      },
      deleteField(){
          this.getField()
      },
      reviseField(){
        this.getField()
      },
        //新增字段选择下拉框
        selectType(value){
            if(value==='INPUT'){
                this.show=0
            }
            if(value==='TEXTAREA'){
                this.show=1
            }
            if(value==='DATE_PICKER'){
                this.show=2
            }
            if(value==='TIME_PICKER'){
                this.show=3

            }
            if(value==='SELECT'){
                this.show=4;

            }
            if(value==='RADIO'){
                this.show=5
            }
            if(value==='CHECKBOX'){
                this.show=6
            }
            if(value==='CASCADER'){
              this.show=7
            }
            if(value === 'FILE')
            {
                this.show='FILE'
            }
            if(value === 'EXPLANATION')
            {
                this.show='EXPLANATION'
            }
           /* if(value==='DATETIME_RANGE'){
                this.show=8
            }
            if(value === 'DATE_RANGE')
            {
              this.show = 10
            }
          if(value === 'TIME_RANGE')
          {
            this.show = 9
          }*/

            this.stepBar=0;
            this.active=1;
            this.fieldType.value=value;
            this.fieldType.label=value;
            this.radioOptions=[{value:'',label:'',_default:'false'},{value:'',label:'',_default:'false'}]
        },

        //编辑字段



        delClick(index) {
            this.radioOptions.splice(index - 1, 1);
            console.log(this.radioOptions)
        },
        radioChange(index){
            console.log(index)
            for (let i =0;i<this.radioOptions.length;i++)
            {
                this.radioOptions[i]._default='true'
            }
            this.radioOptions[index]._default='false'
        },
        resortOptionSet(){
            let _this = this;
            let el = document.getElementById('radioOption');
            let el2=document.getElementById('radioOption2')
            let el3=document.getElementById('radioOption3')

            new Sortable(el,{
                ghostClass: 'blue-background-class',
                animation: 200,
                sort: true,
               handle: '.handles',
                onUpdate:function(event){
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = el.children[newIndex],
                        $oldLi = el.children[oldIndex];
                    // 先删除移动的节点
                    el.removeChild($li)
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if(newIndex > oldIndex) {
                        el.insertBefore($li,$oldLi)
                    } else {
                        el.insertBefore($li,$oldLi.nextSibling)
                    }
                    // 更新items数组
                    let item = _this.radioOptions.splice(oldIndex,1)
                    _this.radioOptions.splice(newIndex,0,item[0])
                    // 下一个tick就会走patch更新
                },

            });
            new Sortable(el2,{
                ghostClass: 'blue-background-class',
                animation: 200,
                sort: true,
              handle: '.handles',
                onUpdate:function(event){
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = el2.children[newIndex],
                        $oldLi = el2.children[oldIndex];
                    // 先删除移动的节点
                    el2.removeChild($li)
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if(newIndex > oldIndex) {
                        el2.insertBefore($li,$oldLi)
                    } else {
                        el2.insertBefore($li,$oldLi.nextSibling)
                    }
                    // 更新items数组
                    let item = _this.radioOptions.splice(oldIndex,1)
                    _this.radioOptions.splice(newIndex,0,item[0])
                    // 下一个tick就会走patch更新

                },
            });
            new Sortable(el3,{
                ghostClass: 'blue-background-class',
                animation: 200,
                sort: true,
              handle: '.handles',
                onUpdate:function(event){
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = el3.children[newIndex],
                        $oldLi = el3.children[oldIndex];
                    // 先删除移动的节点
                    el3.removeChild($li)
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if(newIndex > oldIndex) {
                        el3.insertBefore($li,$oldLi)
                    } else {
                        el3.insertBefore($li,$oldLi.nextSibling)
                    }
                    // 更新items数组
                    let item = _this.radioOptions.splice(oldIndex,1)
                    _this.radioOptions.splice(newIndex,0,item[0])
                    // 下一个tick就会走patch更新

                },
            });
            this.radioOptions=_this.radioOptions
            console.log(this.radioOptions)
        },
        mouseUpClick(){},

        //添加选项
        addOptions() {
            // this.optionnum.push(this.num++)
            // console.log(this.optionnum)

            this.radioOptions.push({value: '',label:'',_default: 'false'});
            console.log(
                'addOptions', this.radioOptions);
        },

        //级联中的事件
        selectOption(value){
            this.showInput2=0
            this.showInput3=0
            this.cascadeOptionSelect3=0
            this.OptionChildren=[]
            this.OptionChildren2=[]
            this.cascadeOptionSelect2=''
            this.cascadeOptionSelect3=''
            this.optionValue.forEach(item=>{
                if(item.value===value)
                {
                    if(item.children.length < 1)
                    {
                        this.OptionChildren=[]
                        this.optionName2=''
                        this.cascadeOptionSelect2=''

                    }
                    else {
                        this.OptionChildren=item.children
                    }
                    this.cascadeOptionChildren=item.value
                }

            })

            console.log(value)
        },

        selectOption2(value){
            this.showInput3=0
            this.cascadeOptionSelect3=''
            this.OptionChildren2=[]
            this.optionValue.forEach(item=>{
                item.children.forEach(items=>{
                    if(items.value===value)
                    {
                        if(items.children.length < 1)
                        {
                            this.OptionChildren2=[]
                            this.optionName3=''
                            this.cascadeOptionSelect3=''
                        }
                        else {
                            this.OptionChildren2=items.children
                        }
                        this.cascadeOptionChildren2=items.value

                    }
                })
            })

        },

        addCascadeOption(){

            if(this.optionName!=='')
            {
                this.optionValue.push({value:this.optionName,label:this.optionName,children: []})
                this.fristOption=1
                this.optionName=''
            }
            else
                this.$message.error('请输入选项名称')



        },
        addCascadeOption2(){
            if(this.optionName2!=='')
            {
                this.optionValue.forEach(item=>{
                    if(item.value===this.cascadeOptionSelect1)
                    {

                        item.children.push({value:this.optionName2,label:this.optionName2,children:[]})
                        this.OptionChildren=item.children
                        /* this.OptionChildren.push({value:this.optionName2,label:this.optionName2,children:[]})*/

                        this.secondOption=1


                    }

                })
                this.optionName2=''
            }
            else
                this.$message.error('请输入选项名称')

        },
        addCascadeOption3(){
            if(this.optionName3!=='')
            {
                this.optionValue.forEach(item=>{
                    item.children.forEach(items=>{
                        if(items.value===this.cascadeOptionSelect2)
                        {
                            console.log(items.value)

                            items.children.push({value:this.optionName3,label:this.optionName3})
                            this.OptionChildren2=items.children
                            /*    this.OptionChildren2.push({value:this.optionName3,label:this.optionName3})*/
                            this.thirdOption=1
                        }
                    })
                })
                this.optionName3=''
            }
            else
                this.$message.error('请输入选项名称')

        },
        delOption1(index,value)
        {
            console.log(value)
            if(this.optionValue[index].children.length > 0)
            {
                this.dialogFormVisibleDelete=true
                this.fristOption=0
                this.fristName=value
                this.deleteOption1=2
                this.deleteOptionIndex=index
            }
            else {
                this.optionValue.splice(index,1)
            }
            this.riveseName1=''
            console.log(value)


        },
        riveseOption1(index1,value){
            if (this.optionValue.length===1)
                this.showInput1=0
            this.showInput1=2
            this.rivese1=index1
            if(this.rivese1===index1)
            {
                this.showOption1=1
            }
            this.riveseIndex1=index1
            this.riveseName1=value
            if(value===this.cascadeOptionSelect1)
            {
                console.log('aaa')
                this.cascadeOptionSelect1Num=1
            }


            console.log(index1,value)

        },
        rivese1Option(){
            if(this.riveseName1!=='')
            {
                if(this.cascadeOptionSelect1Num===1)
                {
                    this.cascadeOptionSelect1=this.riveseName1
                }

                this.optionValue[this.riveseIndex1].value=this.riveseName1
                this.optionValue[this.riveseIndex1].label=this.riveseName1
                this.riveseName1=''
                this.rivese1=''

                this.cascadeOptionSelect1Num=0
            }
            else
                this.$message.error('请输入选项名称')
            this.showInput1=0
        },
        upOption1(index){
            let tempOption = this.optionValue[index];
            this.$set(this.optionValue, index, this.optionValue[index - 1]);
            this.$set(this.optionValue, index - 1, tempOption)

        },
        downOption1(index){
            let temp = this.optionValue[index]
            this.$set(this.optionValue,index,this.optionValue[index+1])
            this.$set(this.optionValue,index+1,temp)

        },
        delOption2(index,value)
        {
            console.log(index,value)
            this.optionValue.forEach((item)=> {
                if (item.value === this.cascadeOptionSelect1) {
                    if(item.children.length > 0)
                    {
                        item.children.forEach(items => {
                            if (items.value===value) {
                                if(items.children.length > 0)
                                {
                                    console.log(items)
                                    this.dialogFormVisibleDelete = true
                                    this.secondOption = 0
                                    this.secondName = value
                                    this.deleteOption2 = 2
                                    this.deleteOption1=0
                                    this.deleteOption2Index = index
                                }
                                else {
                                    item.children.splice(index,1)
                                }
                                console.log('aaa')

                            }
                        })
                    }
                    else {
                        item.children.splice(index,1)
                    }
                }
            })


            this.riveseName2=''


        },
        riveseOption2(index,value){


            this.rivese2=index
            if( this.rivese2===index)
            {
                this.showOption2=1
            }
            this.showInput2=2
            this.riveseName2=value
            this.riveseIndex2=index
            if(value===this.cascadeOptionSelect2)
            {
                this.cascadeOptionSelect2Num=1
            }

        },
        resiveOption2(){
            if(this.riveseName2!=='')
            {
                if(this.cascadeOptionSelect2Num===1)
                    this.cascadeOptionSelect2=this.riveseName2
                this.optionValue.forEach(item=>{
                    if(item.value===this.cascadeOptionSelect1)
                    {
                        item.children[this.rivese2].value=this.riveseName2
                        item.children[this.rivese2].label=this.riveseName2

                        this.OptionChildren[this.rivese2]=item.children[this.rivese2]
                        this.rivese2=''
                    }
                })
                this.cascadeOptionSelect2Num=0


            }
            else
                this.$message.error('请输入选项名称')
            this.showInput2=0


        },
        upOption2(index){
            let tempOption = this.OptionChildren[index];
            this.$set(this.OptionChildren, index, this.OptionChildren[index - 1]);
            this.$set(this.OptionChildren, index - 1, tempOption)
            console.log(this.OptionChildren)
            console.log(this.optionValue)

        },
        downOption2(index){
            let temp = this.OptionChildren[index]
            this.$set(this.OptionChildren,index,this.OptionChildren[index+1])
            this.$set(this.OptionChildren,index+1,temp)
            console.log(this.OptionChildren)
            console.log(this.optionValue)
        },
        delOption3(index,value)
        {
            this.OptionChildren2.splice(index,1)
            for (let i = 0; i< this.optionValue.length;i++)
            {
                for (let j =0;j<this.optionValue[i].children.length;j++)
                {
                    for (let k=0;k<this.optionValue[i].children[j].length ;k++)
                    {

                        if(this.optionValue[i].children[j].children[k].value===value)
                            this.optionValue[i].children[j].children.splice(k,1)
                    }



                }
            }
            this.riveseName3=''

            this.cascadeOptionSelect3=''




        },
        riveseOption3(index1,value){
            this.rivese3=index1
            this.showInput3=2
            this.riveseName3=value
            this.riveseIndex3=index1
            if(value===this.cascadeOptionSelect3)
                this.cascadeOptionSelect3Num=1
        },
        rivese3Option(){
            this.showInput3=0
            if(this.riveseName3!=='')
            {
                if(this.cascadeOptionSelect3Num===1)
                    this.cascadeOptionSelect3=this.riveseName3
                this.optionValue.forEach(item=>{
                    item.children.forEach(items=>{
                        items.children[this.rivese3].value=this.riveseName3
                        items.children[this.rivese3].label=this.riveseName3
                        this.OptionChildren2[this.rivese3]=items.children[this.rivese3]

                        this.rivese3=''
                    })
                })

                this.cascadeOptionSelect3Num=0
            }
            else
                this.$message.error('请输入选项名称')


        },
        upOption3(index){
            let tempOption = this.OptionChildren2[index];
            this.$set(this.OptionChildren2, index, this.OptionChildren2[index - 1]);
            this.$set(this.OptionChildren2, index - 1, tempOption)

        },
        downOption3(index){
            let temp = this.OptionChildren2[index]
            this.$set(this.OptionChildren2,index,this.OptionChildren2[index+1])
            this.$set(this.OptionChildren2,index+1,temp)

        },
        delCascadeOption1(){
            this.optionValue.forEach(item=>{
                if(item.children.length > 0 || this.OptionChildren.length > 0)
                {
                    this.dialogFormVisibleDelete2=true
                    this.fristOption=0
                }
                else
                {
                    this.optionValue=[]
                    this.OptionChildren2=[]
                    this.OptionChildren=[]
                    this.addCascade=0
                    this.addCascade2=0
                    this.addCascade1=0
                    this.cascadeOptionSelect1=''
                    this.showInput1=0
                }


            })


        },
        delCascadeOption2(){
            this.optionValue.forEach(item=>{
                item.children.forEach(items=>{
                    console.log(items)
                    if(items.children.length > 0 || this.OptionChildren2.length > 0)
                    {
                        this.dialogFormVisibleDelete2=true
                        this.secondOption=0
                    }
                    else
                    {
                        this.cascadeOptionSelect2=''
                        this.addCascade=0
                        this.addCascade2=0
                        items.children=[]
                        this.OptionChildren=[]
                        this.OptionChildren2=[]
                        this.optionValue.forEach(item=>{
                            if(item.value===this.cascadeOptionSelect1)
                                item.children=[]
                        })
                        this.showInput2=0
                    }
                })
            })
        },
        delCascadeOption3(){
            this.optionValue.forEach(item=>{
                item.children.forEach(items=>{
                    items.children=[]
                    this.OptionChildren2=[]
                    this.cascadeOptionSelect3=''
                    this.addCascade2=0
                    this.showInput3=0
                })
            })

        },
        inputShow1(){
            this.showInput1=1
        },
        inputShow2(){
            if(this.cascadeOptionSelect1!=='')
            {
                this.showInput2=1
            }
            else
                this.$message.error('请先选择下拉值')

        },
        inputShow3(){
            if(this.cascadeOptionSelect2!=='')
            {
                this.showInput3=1
            }
            else
                this.$message('请先选择下拉值')

        },
        cacleInput1(){
            this.showInput1=0
            /* this.showInput=2*/
        },
        cacleInput2(){
            this.showInput2=0
            /* this.showInput=2*/
        },
        cacleInput3(){
            this.showInput3=0
            /* this.showInput=2*/
        },
        cacleRivese1(){
            this.showInput1=0
            this.rivese1=''
        },
        cacleRivese2(){
            this.showInput2=0
            this.rivese2=''
        },
        cacleRivese3(){
            this.showInput3=0
            this.rivese3=''
        },
        addCascadePullDown(){
            if(this.addCascade1===0)
            {
                this.addCascade1=1
                this.cascadeOptionSelect=''
            }
            if(this.optionValue.length < 1)
            {
                this.$message.error('请添加选项')
            }
            else
            {
                if(this.addCascade2===3)
                {
                    this.$message.error('只能添加三级下拉哦')
                }
                else
                {
                    if(this.addCascade1===1&&this.addCascade!==2&& this.addCascade2!==3)
                    {
                        if(this.cascadeOptionSelect1!==''&&this.addCascade1===1)
                        {
                            this.addCascade=2
                        }
                        else {
                            this.$message.error('请选择下拉')
                        }
                    }
                    else if(this.addCascade1===1&&this.addCascade===2&& this.addCascade2!==3)
                    {
                        if(this.cascadeOptionSelect2!=='')
                        {
                            this.addCascade2=3
                            this.cascadeOptionSelect3=''
                        }
                        else {
                            this.$message.error('请选择下拉')
                        }
                    }
                }
            }
        },
      deleteAllOption(){
        if( this.fristOption===0)
        {
          this.optionValue=[]
          this.OptionChildren2=[]
          this.OptionChildren=[]
          this.fristOption=1
          this.cascadeOptionSelect1=''
          this.fristOption=1
          this.addCascade2=0
          this.addCascade=0

        }
        if(this.secondOption===0)
        {
          this.OptionChildren=[]
          this.OptionChildren2=[]
          this.optionValue.forEach(item=>{
            item.children=[]
            this.secondOption=1
            this.cascadeOptionSelect2=''
            this.addCascade2=0
            this.addCascade=0
          })

        }
        this.dialogFormVisibleDelete2=false
      },
      deleteOption(){

        if(this.deleteOption1===2){
          console.log(this.optionValue)
          console.log(this.fristName)
          this.optionValue.forEach(item=>{
            if(item.value===this.fristName)
            {
              this.optionValue.splice(this.deleteOptionIndex,1)
              this.OptionChildren=[]
              this.OptionChildren2=[]
              this.cascadeOptionSelect1=''
              this.cascadeOptionSelect2=''
              this.cascadeOptionSelect3=''
            }

          })
          this.deleteOption1=0


          console.log(this.optionValue)
          console.log(this.OptionChildren)
        }
        if(this.deleteOption2===2){
          this.optionValue.forEach(item=>{
            console.log(this.cascadeOptionSelect1)
            if(item.value===this.cascadeOptionSelect1)
            {
              item.children.forEach(items=>{
                items.children.splice(this.deleteOption2Index,1)
                this.OptionChildren.splice(this.deleteOption2Index,1)
                this.OptionChildren2=[]
                this.cascadeOptionSelect2=''
                this.cascadeOptionSelect3=''
              })
            }
          })

          if(this.cascadeOptionSelect2===this.secondName)
          {
            this.cascadeOptionSelect3=''
          }

          this.deleteOption2=0
        }
        this.dialogFormVisibleDelete=false
      },
        changeClick(value){
            console.log(value)
        },
    },

}
</script>
<style  lang="less">
@import "./../../assets/less/formManage/fieldManage.less";
.disa-webhook {
    line-height: 60px;
    height: 60px;
    font-size: 18px;
    color: #303133;
    margin-left: 14px;
    opacity: 0.6;
    cursor: pointer;
    span {
        display: inline-block;
        font-size: 14px;
        margin-left: 8px;
        margin-top: 12px;
    }
}
.blue-background-class{
}
.el-menu-item-group__title{
    padding: 0!important;
}
.el-submenu .el-menu-item{
    height: 48px;
    line-height: 48px;
    width: 150px;
    padding: 0 45px;
    min-width: 150px;
}
.activeClass{
    border-right: 2px solid #366AFF;
}
    .el-form-item__label{
        line-height: 1;
    }
// .el-submenu__title{
//     padding: 0 10px!important;
// }
.el-pagination{
    margin: 0;
}
    .fileClass{
      /deep/.el-upload-list{
          display: none;
      }
        .upload-demo{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
        }
        .upload--picture-card{
            background-color: #fbfdff;
            border: 1px dashed #c0ccda;
            border-radius: 6px;
            width: 40px;
            height: 40px;
            line-height: 56px;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            font-size:28px;
            color:#8c939d;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>