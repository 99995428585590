<template>
    <div id="formManage">
        <!-- <div class="content-header" style="width:100%">
            <div class="leftInfo">
                <span class="headerName">{{activePageInfo.headerName}}</span>
                <div class="new-add-button"><el-button v-if="botVersion != 2 && RP_Visible('OPEN_FORM_ADD')" type="primary" @click="goToAddForm()" style="margin-right: 24px">新增表单</el-button></div>
            </div>
        </div> -->
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenaljicheng"></i>
                <span class="page-name">接口对接</span>
            </div>
            <div slot="top-header-right" class="top-header-right">
                <div class="new-add-button"><el-button v-if="botVersion != 2 && RP_Visible('OPEN_FORM_ADD')" type="primary" @click="goToAddForm()" style="margin-right: 24px">新增表单</el-button></div>
            </div>
        </page-top-header>
        <div class="formmanage-content">
            <div class="left-nav-menu">
                <el-menu
                    :default-openeds="['/main/form']"
                    router
                     active-text-color="#366AFF"
                    :default-active = "currentPath"
                    class="el-menu-vertical-demo">
                    <el-menu-item index="/main/webhook" style="padding-left: 20px" v-if="RP_Visible('OPEN_WEBHOOK')" :class="{activeClass:this.$route.path==='/main/webhook'}">
                        <i class="el-icon-connection"></i>
                        <span slot="title">Webhook</span>
                    </el-menu-item>
                    <el-submenu index="/main/form" >
                        <template slot="title" >
                        <i class="el-icon-set-up"></i>
                        <span>自定义表单</span>
                        </template>
                        <el-menu-item-group>
                        <el-menu-item index="/main/fieldManage" :class="{activeClass:this.$route.path==='/main/fieldManage'}">字段管理</el-menu-item>
                        <el-menu-item index="/main/formManage" :class="{activeClass:this.$route.path==='/main/formManage'}">表单管理</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item
                        index="/main/epidemic"
                        v-if="isShow"
                        :class="{ activeClass: this.$route.path === '/main/epidemic',epidemic_view: true}"
                        >
                        <template slot="title">
                            <i class="arsenal_icon" style="font-size:16px;margin-right:10px">&#xed6d;</i>
                            <span>疫区管理</span>
                        </template>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="right-content">

                <div v-if="botVersion == 2">
                    <VersionTip tipIndex="4"/>
                </div>
                <div v-else class="list-content">
                            <form-manage-item
                                :formList="formList"
                                @copyForm="copyForm"
                                @deleteForm="deleteForm"
                            ></form-manage-item>
                    <div style="position: absolute;bottom: 0px;right: 8px;left: 0;text-align: right;height: 48px;line-height: 48px">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page=formPage
                                :page-sizes="[10, 20, 30, 40]"
                                :page-size=formCurrentSize
                                layout="total, sizes, prev, pager, next, jumper"
                                :total=formTotal>
                        </el-pagination>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
import pageTopHeader from '../../components/page-top-header.vue'
import FormManageItem from "./formManageItem";

import Popup from "@/components/popup";
import VersionTip from "./../version/PversionTip.vue";

export default {
    components: {FormManageItem, VersionTip, Popup,pageTopHeader},
    data(){
        return{
            botVersion: 0,

            currentPath:'',
            currentPage4:20,
            activePageInfo: {
             headerName: "接口对接"
           },
          formPage:1,
          formCurrentSize:20,
          formList:[],
          formTotal:0
        }
    },
    computed: {
        isShow() {
        let mainId = localStorage.getItem('_mainId')
        return mainId == '90df4764122240de939331d372546c28' || mainId == '1419b09cb2ce11ea8f0900163e0964d5'
        }
    },
    methods:{
        getCurrentUrl(){
            this.currentPath = this.$route.path;
            console.log('path:',this.currentPath);
        },
        goToAddForm() {
            this.$router.push({
                name: 'addForm'
            });
        },
      copyForm(){
          this.getFormList()
      },
      deleteForm(){
        this.getFormList()
      },
      getFormList(){
          this.FetchGet('/api/form/page?page='+this.formPage+'&size='+this.formCurrentSize).then(res=>{
           if(res.code==='0')
           {
             this.formTotal=res.data.total
             this.formList=res.data.list
             console.log(this.formList)
           }
          })
      },
        handleSizeChange(value){
          console.log(value)
          this.formCurrentSize=value
          this.getFormList()
        },
        handleCurrentChange(value){
          console.log(value)
          this.formPage=value
          this.getFormList()
        },
    },
    mounted(){
        this.getCurrentUrl();
        this.getFormList()

        this.botVersion = localStorage.getItem('_bot_version');
    }
}
</script>
<style lang="less">
@import "./../../assets/less/formManage/formManage.less";
.disa-webhook {
    line-height: 60px;
    height: 60px;
    font-size: 18px;
    color: #303133;
    margin-left: 14px;
    opacity: 0.6;
    cursor: pointer;
    span {
        display: inline-block;
        font-size: 14px;
        margin-left: 8px;
        margin-top: 12px;
    }
}
.el-form-item__content{
    text-align: left;
}
.el-menu-item-group__title{
  padding: 0!important;
}
.el-submenu .el-menu-item{
    height: 48px;
    line-height: 48px;
    width: 150px;
    padding: 0 45px;
    min-width: 150px;
}
.activeClass{
    border-right: 2px solid #366AFF;
}
// .el-submenu__title{
//     padding: 0 10px!important;
// }
.el-pagination{
    margin: 0;
}
</style>