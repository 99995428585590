<template>
    <div id="addForm">
      <div class="content-header" style="width:100%">
            <div class="leftInfo">
                <span class="back" @click="goToManageForm()"><i class="el-icon-arrow-left"></i>返回表单管理</span>
                <div class="headerName" v-if="this.$route.query.id===undefined">新增表单</div>
                <div class="headerName" v-if="formTitleEdit">修改表单</div>
                <div class="headerName" v-if="formTitleLook">查看表单</div>
                <div class="operateBtn">
                    <el-button size="small" @click="goToManageForm">关闭</el-button>
                    <el-button type="primary" size="small" v-if="(this.$route.query.release === false || this.$route.query.release == 'false' ||this.$route.query.release=== undefined ) && editable" @click="preserveForm">保存</el-button>
                    <el-button size="small" @click="issueFormClick" v-if="(this.$route.query.release === false || this.$route.query.release == 'false' ||this.$route.query.release=== undefined ) && editable">发布</el-button>
                </div>
            </div>
        </div>
        <div class="formManage-content">
            <div class="left-nav-menu" :class="{notClickClass: this.$route.query.id!==undefined && ((!RP_Visible('OPEN_FORM_UPDATE') && !(this.$route.query.release === true || this.$route.query.release == 'true')) || (this.$route.query.release === true || this.$route.query.release == 'true'))}" style="padding: 0 4px">
                <div class="prompt-text">
                    <div>字段<span class="gray-text">(点击字段即可添加到表单)</span></div>
                    <div class="under-line"></div>
                </div>
              <el-scrollbar style="height: calc(100% - 50px);">
                <div class="field-content">
                  <div v-for="(item,index) in fieldList" :key="index">
                    <div class="field-name" @click="fieldClick(item,item.name,index)">{{item.name}}</div>
                  </div>
                </div>
              </el-scrollbar>
            </div>


             <div class="right-content">
                     <div class="form-content">
                         <div class="formName">
                             <div class="formNameText"><span style="color: red">*</span><span style="font-size: 14px">表单名称</span></div>
                             <div class="formNameInput">
                                 <el-input
                                         placeholder="请输入表单名称"
                                         v-model="formName"
                                         clearable
                                         maxlength="20"
                                         show-word-limit
                                         :disabled="IsDisabled"
                                 >
                                 </el-input>
                             </div>
                         </div>
                         <div class="formDec">
                             <div class="formDecText">表单描述</div>
                             <div class="formDecInput">
                                 <el-input
                                         placeholder="请输入表单描述"
                                         v-model="formDescribe"
                                         clearable
                                         :disabled="IsDisabled"
                                 ></el-input>
                             </div>
                         </div>
                         <div class="formName">
                             <div class="formNameText"><span style="color: red">*</span><span style="font-size: 14px">表单适用机器人</span></div>
                             <div v-if="roleId == 1" class="formBotScope">
                                <el-radio-group :disabled="IsDisabled" v-model="botScope">
                                  <el-radio :label="0">全部机器人</el-radio>
                                  <el-radio :label="1">部分机器人</el-radio>
                                </el-radio-group>
                                <el-select :props="defaultProps" v-if="botScope == 1" :disabled="IsDisabled" v-model="selectBots" multiple placeholder="请选择机器人">
                                  <el-option
                                    v-for="item in botOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                  </el-option>
                                </el-select>
                             </div>
                             <div v-if="roleId != 1" class="formBotScope">
                                <el-select :props="defaultProps" :disabled="IsDisabled" v-model="selectBots" multiple placeholder="请选择机器人">
                                  <el-option
                                    v-for="item in botOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                  </el-option>
                                </el-select>
                             </div>
                         </div>
                     </div>
                     <div>
                         <el-divider></el-divider>
                     </div>

                     <div style="width: 70%;margin: 0 auto ;"
                          class="filedDefault"
                          :class="{notClickClass: this.$route.query.id!==undefined && ((!RP_Visible('OPEN_FORM_UPDATE') && !(this.$route.query.release === true || this.$route.query.release == 'true')) || (this.$route.query.release === true || this.$route.query.release == 'true'))}"
                     >
                         <div class="box" id="box">
                             <div
                                     class="cell"
                                     :class="getClass(item)"
                                     v-for="(item, index) in addFormFiled"
                                     :key="index"
                                     :disabled="IsDisabled"

                             >
                                <div v-if="item.type==='EXPLANATION'" style="display: flex;width: 100%;position: relative;flex-direction: row">
                                      <div style="flex: 0.05 0.05 0" class="handles">
                                          <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left: 5px;cursor: move" @mouseenter="userDrop"/>
                                      </div>
                                      <div style="flex: 0.95 0.95 0;margin-left: 15px;width: 97%">
                                          <div   style="display: flex;height: 32px;line-height: 32px">
                                            <span style="flex: 1 1 0;text-align: left">
                                                <span style="font-size: 14px">{{item.name}}</span>
                                            </span>
                                              <div style="text-align: right;width: 17.2px;margin-right: 5px">
                                                  <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;" @click="delRightField(index,item)"/>
                                              </div>
                                          </div>
                                          <div class="typeIsTextareaInput" style="margin-right: 10px;">
                                              <div style="background-color: #fdf6ec;color: #E6A23C;border-radius: 4px;padding: 8px 16px;text-align: left;height: 50px;overflow-y: auto;">
                                                {{item.extInfo.descriptionText}}
                                              </div>
                                          </div>
                                      </div>
                                </div>
                                 <div v-if="item.type==='SELECT' || item.type==='OPTION'">
                                     <div style="display: flex;">
                                         <div style="flex: 0.15 0.15 0" class="handles">
                                             <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;cursor: move;margin-left: 5px" @mouseenter="userDrop"/>
                                         </div>
                                         <div style="flex: 0.85 0.85 0;margin-left: 15px;width: 97%;">
                                             <div style=" display: flex;height: 32px;line-height: 32px; width: 97%;margin:0 auto;">
                                       <span style="flex: 1 1 0;text-align: left;">
                                           <div style="font-size: 14px;float: left" class="LongName">{{item.name}}</div>
                                       </span>
                                                 <div style="text-align: right;width: 17.2px;height: 16px">
                                                     <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-right: 5px;" @click="delRightField(index,item)"/>
                                                 </div>
                                             </div>
                                             <div class="typeIsSelectSelect">
                                                 <el-select v-model=item.extInfo.value
                                                            clearable
                                                            filterable
                                                            style="width: 96%;"
                                                            placeholder="请选择"
                                                 >

                                                      <el-option
                                                              v-for="(item,index) in item.extInfo.option"
                                                              :key=index
                                                              :label=item.label
                                                              :value=item.value

                                                      >
                                                      </el-option>
                                                 </el-select>
                                             </div>

                                         </div>
                                     </div>
                                 </div>

                                 <div v-if="item.type==='CASCADER'">
                                     <div  style="display: flex;">
                                         <div  style="flex: 0.15 0.15 0" class="handles">
                                             <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left:5px;cursor: move" @mouseenter="userDrop"/>
                                         </div>
                                         <div style="flex: 0.85 0.85 0;margin-left: 15px;width: 97%;">
                                             <div style="display: flex;width: 97%;margin: 0 auto;line-height: 32px;">
                                                 <div style=" flex: 1 1 0;overflow: hidden">
                                                     <div style="font-size: 14px;float: left;margin-right: 10px;text-align: left" class="LongName">{{item.name}}</div>
                                                 </div>
                                                 <div style="height: 16px;text-align: right">
                                                     <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;" @click="delRightField(index,item)"/>
                                                 </div>
                                             </div>
                                             <div>
                                                  <el-cascader
                                                          v-model=item.extInfo.value
                                                          :options="item.extInfo.cascade"
                                                          style="width: 96%"
                                                  ></el-cascader>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                  <div v-if="item.type==='DATE_PICKER'" >
                                      <div style="display: flex"
                                      >
                                          <div style=" flex: 0.15 0.15 0;
                                                  position: relative;"
                                               class="handles"
                                          >
                                              <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 60%;left: 50%;cursor: move" @mouseenter="userDrop"/>
                                          </div>
                                          <div style="flex: 0.85 0.85 0;">
                                              <div style="text-align: right;display: flex;width: 98%;margin:0 auto">
                                                  <div style="flex: 1 1 0;text-align: left;height: 32px;line-height: 32px">
                                                      <div style="font-size: 14px;float: left;margin-left: 10px" class="LongName"> {{item.name}}</div>

                                                  </div>
                                                  <div style="text-align: right;width: 17.2px">
                                                      <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;margin-right: 5px" @click="delRightField(index,item)"/>
                                                  </div>
                                              </div>
                                              <div style="width: 100%;margin: 0 auto">
                                                  <el-date-picker
                                                          v-model=item.extInfo.value
                                                          type="date"
                                                          placeholder="选择日期"
                                                          style="width: 90%"
                                                  >
                                                  </el-date-picker>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div v-if="item.type==='TIME_PICKER'" >
                                      <div style="display: flex">
                                          <div
                                                  style=" flex: 0.15 0.15 0;
                                                  position: relative;"
                                                  class="handles"
                                          >
                                              <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 60%;left: 50%;cursor: move" @mouseenter="userDrop"/>
                                          </div>
                                          <div style="flex: 0.85 0.85 0">
                                              <div style="text-align: right;display: flex;width: 98%;margin:0 auto">
                                                  <div style="flex: 1 1 0;text-align: left;height: 32px;line-height: 32px;">
                                                      <div style="font-size: 14px;float: left;margin-left: 10px" class="LongName">{{item.name}}</div>
                                                  </div>
                                                  <div style="text-align: right;width: 17.2px">
                                                      <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;margin-right: 5px" @click="delRightField(index,item)"/>
                                                  </div>
                                              </div>
                                              <div style="width: 100%;margin: 0 auto">
                                                  <el-time-picker
                                                          v-model=item.extInfo.value
                                                          placeholder="选择时间"
                                                          style="width: 90%"
                                                  >
                                                  </el-time-picker>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                 <div v-if="item.type==='INPUT'" style="display: flex;width: 100%;position: relative;flex-direction: row">
                                     <div style="flex: 0.05 0.05 0" class="handles">
                                         <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left: 5px;cursor: move" @mouseenter="userDrop"/>
                                     </div>
                                     <div style="flex: 0.95 0.95 0;margin-left: 15px;width: 97%">
                                         <div style="display: flex;height: 32px;line-height: 32px">
                                             <div style="flex: 1 1 0;text-align: left">
                                                 <span style="font-size: 14px">{{item.name}}</span>
                                             </div>
                                             <div style="width: 17.2px;height: 16px;margin-right: 5px">
                                                 <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;" @click="delRightField(index,item)"/>
                                             </div>
                                         </div>
                                         <div class="typeIsSingeTextInput" style="margin-right: 10px;">
                                             <el-input
                                                     placeholder="请输入单行文本的内容"
                                                     v-model=item.extInfo.value
                                                     clearable
                                                     style="width: 100%;"
                                             >
                                             </el-input>
                                         </div>
                                     </div>
                                 </div>
                                  <div v-if="item.type==='TEXTAREA'" style="display: flex;width: 100%;position: relative;flex-direction: row">

                                      <div style="flex: 0.05 0.05 0" class="handles">
                                          <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left: 5px;cursor: move" @mouseenter="userDrop"/>
                                      </div>
                                      <div style="flex: 0.95 0.95 0;margin-left: 15px;width: 97%">
                                          <div   style="display: flex;height: 32px;line-height: 32px">
                                            <span style="flex: 1 1 0;text-align: left">
                                                <span style="font-size: 14px">{{item.name}}</span>
                                            </span>
                                              <div style="text-align: right;width: 17.2px;margin-right: 5px">
                                                  <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;" @click="delRightField(index,item)"/>
                                              </div>
                                          </div>
                                          <div class="typeIsTextareaInput" style="margin-right: 10px;">
                                              <el-input
                                                      type="textarea"
                                                      :rows="2"
                                                      placeholder="请输入多行文本的内容"
                                                      v-model=item.extInfo.value
                                                      style="width: 100%;"
                                              >
                                              </el-input>
                                          </div>
                                      </div>
                                  </div>

                                  <div v-if="item.type==='RADIO'" style="display: flex;width: 100%;position: relative;flex-direction: row">

                                      <div style="flex: 0.05 0.05 0" class="handles">
                                          <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left: 5px;cursor: move" @mouseenter="userDrop"/>
                                      </div>
                                      <div style="flex: 0.95 0.95 0;margin-left: 15px;width: 97%">
                                          <div style="text-align: right;display: flex;margin:0 auto 10px auto">
                                            <span style="margin-top: 10px;flex: 1 1 0;text-align: left;white-space: nowrap">
                                               <span style="font-size: 14px"> {{item.name}}</span>
                                            </span>
                                              <div style="text-align: right;width: 17.2px;height: 16px;margin-right: 5px">
                                                  <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;" @click="delRightField(index,item)"/>
                                              </div>
                                          </div>
                                          <div style="text-align:left;">
                                              <el-radio-group v-model=item.extInfo.value style="background-color: whitesmoke">
                                                  <el-radio
                                                          v-for="(item,index) in item.extInfo.option"
                                                          :label="item.label"
                                                          :key="index"
                                                          :value="item.value"
                                                          style="margin-right: 24px"
                                                  ></el-radio>


                                              </el-radio-group>
                                          </div>
                                      </div>

                                  </div>
                                  <div v-if="item.type==='CHECKBOX'" style="display: flex;width: 100%;position: relative;flex-direction: row">

                                      <div style="flex: 0.05 0.05 0" class="handles">
                                          <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left: 5px;cursor: move" @mouseenter="userDrop"/>
                                      </div>
                                      <div  style="flex: 0.95 0.95 0;margin-left: 15px;width: 97%">
                                          <div style="text-align: right;display: flex;margin:0 auto 10px auto">
                                            <span style="margin-top: 10px;flex: 1 1 0;text-align: left">
                                                 <span style="font-size: 14px">{{item.name}}</span>
                                            </span>
                                              <div style="text-align: right;width: 17.2px;margin-right: 5px">
                                                  <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;" @click="delRightField(index,item)"/>
                                              </div>
                                          </div>
                                          <div style="text-align:left;padding-top: 5px">
                                              <el-checkbox-group v-model=item.extInfo.value>
                                                  <el-checkbox
                                                          v-for="(items,index) in item.extInfo.option"
                                                          :label=items.value
                                                          :key="index"
                                                          style="margin-right: 24px"
                                                  >{{items.label}}</el-checkbox>
                                              </el-checkbox-group>
                                          </div>
                                      </div>

                                  </div>
                                 <div v-if="item.type === 'FILE'" style="display: flex;width: 100%;position: relative;flex-direction: row">
                                     <div style="flex: 0.05 0.05 0" class="handles">
                                         <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;margin-left: 5px;cursor: move" @mouseenter="userDrop"/>
                                     </div>
                                     <div  style="flex: 0.95 0.95 0;margin-left: 15px;width: 97%">
                                         <div style="display: flex;margin:0 auto 10px auto;justify-content: flex-end">
                                             <div style="margin-top: 10px;flex: 1 1 0;text-align: left">
                                                 <span style="font-size: 14px"> {{item.name}}</span>
                                             </div>
                                             <div style="text-align: right;width: 17.2px;margin-right: 5px">
                                                 <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;" @click="delRightField(index,item)"/>
                                             </div>
                                         </div>
                                         <div style="text-align:left;padding-left: 10px;padding-bottom: 10px">
                                             <el-upload
                                                     :action="postAction"
                                                     :data="uploadAvatarParams"
                                                     :on-preview="handlePictureCardPreview"
                                                     :on-success="handleAvatarSuccess"
                                                     :accept="acceptCom"
                                                     :limit=item.extInfo.limitNum?item.extInfo.limitNum:1
                                                     :on-remove="handleRemove">
                                                 <i slot="default" class="el-icon-plus picture-show"></i>
                                             </el-upload>
                                         </div>
                                     </div>
                                 </div>
                               <!--  <div v-if="item.type==='DATETIME_RANGE'"style="display: flex;width: 100%" >

                                  <div style=" flex: 0.05 0.05 0;
                                                 position: relative;" class="handles">
                                    <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 45%;left: 50%;cursor: move" @mouseenter="userDrop"/>
                                  </div>
                                  <div style="flex: 0.95 0.95 0;">
                                    <div style="text-align: right;display: flex;width: 98%;margin:0 auto">
                                      <div style="flex: 1 1 0;text-align: left;height: 32px;line-height: 32px">
                                        <div style="font-size: 14px;float: left;margin-left: 5px" class="LongName"> {{item.name}}</div>

                                      </div>
                                      <div style="text-align: right;width: 17.2px">
                                        <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;margin-right: 5px" @click="delRightField(index,item)"/>
                                      </div>
                                    </div>
                                    <div style="margin: 0 10px">
                                      <el-date-picker
                                          v-model=item.extInfo.value
                                          type="datetimerange"
                                          range-separator="至"
                                          start-placeholder="开始日期"
                                          end-placeholder="结束日期"
                                          style="width: 100%;"
                                      >
                                      </el-date-picker>

                                    </div>
                                  </div>
                              </div>-->
                              <!--  <div v-if="item.type === 'DATE_RANGE'">
                                 <div style="display: flex"
                                 >
                                   <div style=" flex: 0.15 0.15 0;
                                                  position: relative;"
                                        class="handles"
                                   >
                                     <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 60%;left: 50%;cursor: move" @mouseenter="userDrop"/>
                                   </div>
                                   <div style="flex: 0.85 0.85 0;">
                                     <div style="text-align: right;display: flex;width: 98%;margin:0 auto">
                                       <div style="flex: 1 1 0;text-align: left;height: 32px;line-height: 32px">
                                         <div style="font-size: 14px;float: left;margin-left: 10px" class="LongName"> {{item.name}}</div>

                                       </div>
                                       <div style="text-align: right;width: 17.2px">
                                         <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;margin-right: 5px" @click="delRightField(index,item)"/>
                                       </div>
                                     </div>
                                     <div style="width: 100%;margin: 0 auto">
                                       <el-date-picker
                                           v-model="dateValue"
                                           type="daterange"
                                           range-separator="至"
                                           start-placeholder="开始日期"
                                           end-placeholder="结束日期"
                                           style="width: 90%"
                                       >
                                       </el-date-picker>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div v-if="item.type === 'TIME_RANGE'">
                                 <div style="display: flex"
                                 >
                                   <div style=" flex: 0.15 0.15 0;
                                                  position: relative;"
                                        class="handles"
                                   >
                                     <img src="../../assets/images/moveAdjust.png" style="width: 16px;height: 16px;position: absolute;top: 60%;left: 50%;cursor: move" @mouseenter="userDrop"/>
                                   </div>
                                   <div style="flex: 0.85 0.85 0;">
                                     <div style="text-align: right;display: flex;width: 98%;margin:0 auto">
                                       <div style="flex: 1 1 0;text-align: left;height: 32px;line-height: 32px">
                                         <div style="font-size: 14px;float: left;margin-left: 10px" class="LongName"> {{item.name}}</div>

                                       </div>
                                       <div style="text-align: right;width: 17.2px">
                                         <img src="../../assets/images/delred.png" style="width: 16px;height: 16px;margin-top: 10px;margin-right: 5px" @click="delRightField(index,item)"/>
                                       </div>
                                     </div>
                                     <div style="width: 100%;margin: 0 auto">
                                       <el-time-picker
                                           is-range
                                           arrow-control
                                           v-model="timeValue"
                                           range-separator="至"
                                           start-placeholder="开始时间"
                                           end-placeholder="结束时间"
                                           placeholder="选择时间范围"
                                           style="width: 90%"
                                       >
                                       </el-time-picker>
                                     </div>
                                   </div>
                                 </div>
                               </div>-->

                             </div>
                         </div>
                     </div>
                     <div v-if="addFormFiled.length > 0">
                          <el-divider></el-divider>
                     </div>
                     <div style="width: 70%;margin: 0 auto 20px auto;">
                         <div style="text-align: left;padding: 14px 0;">
                             选择WebHook <span style="color: #F59A23">(如果要与第三方系统进行数据交互，下方为必填字段)</span>
                         </div>
                         <div>
                             <el-select
                                     v-model="WebHookValue"
                                     placeholder="请选择WebHook"
                                     style="width: 100%"
                                     @change="changeWebHookValue"
                                     clearable
                                     :disabled="IsDisabled"
                             >
                                 <el-option
                                         v-for="item in webhookApiList"
                                         :key="item.id"
                                         :label="item.label"
                                         :value="item.id">
                                 </el-option>
                             </el-select>
                         </div>
                         <div>
                             <div style="text-align: left;padding: 14px 0;">
                                 请完善WebHook参数与表单字段的关联关系
                             </div>
                             <div>
                                 <el-table
                                         :data="filterAddFormFiled"
                                         border
                                         style="width: 100%"
                                         :row-class-name="getRowClass"
                                 >
                                     <el-table-column
                                             prop="name"
                                             label="表单字段"
                                             align="center"
                                     >
                                     </el-table-column>
                                     <el-table-column
                                             label="WebHook参数"
                                             align="center"
                                     >
                                         <template slot-scope="scope">
                                             <el-select
                                                     v-model="scope.row.extInfo.WebHookParameter"
                                                     placeholder="请先选择webHook参数"
                                                     style="width: 100%"
                                                     clearable
                                                     :disabled="IsDisabled"
                                                     @change="(value) =>{ return selectWebHook(value,scope.$index)}"
                                                     empty
                                             >
                                                 <el-option disabled value="a"  v-if="WebHookValue===''">请先选择WebHook参数</el-option>
                                                 <el-option disabled value="b"  v-if="WebHookValue!==''&&webhookParameter.length===0">无数据</el-option>
                                                 <el-option
                                                         v-for="(item,index) in webhookParameter"
                                                         :key=index
                                                         :label="item.key"
                                                         :value="item.key"
                                                         v-if="WebHookValue!==''&&webhookParameter.length > 0"
                                                 >
                                                 </el-option>
                                             </el-select>
                                         </template>
                                     </el-table-column>
                                        <el-table-column
                                             prop="required"
                                             label="是否必填"
                                             align="center"
                                             width="100px"
                                        >
                                        <template slot-scope="scope">
                                          <el-checkbox :disabled="IsDisabled" @change="(value) => { return changeRequired(value,scope) }" :value="scope.row.required"></el-checkbox>
                                        </template>
                                     </el-table-column>
                                 </el-table>
                             </div>
                         </div>
                     </div>
             </div>
        </div>

        <el-dialog
                title="发布表单"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                custom-class="formDialog"
                >
            <span style="text-align: left">发布之后表单将不能编辑，是否确认发布？</span>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="publishForm">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
    import Sortable from "sortablejs";
export default {
    data(){
        return{
            editable: false,
            roleId: 1,
            dialogVisible:false,
            IsDisabled:false,
            currentPath:'',
            formName:'',
            formDescribe:'',
            botScope: 0,
            botOptions: [],
            selectBots: [],
            defaultProps: {
                value:'id',
                label: 'name',
            },
            field:[
                {id:1,name:'字段1',type:'SELECT'},
                {id:2,name:'字段2',type: 'CASCADE'},
                {id:3,name:'字段3',type: 'INPUT'},
                {id:4,name:'字段4',type: 'SELECT'},
                {id:5,name:'字段5',type: 'CASCADE'},
                {id:6,name:'字段6',type: 'SELECT'},

            ],
            addFormFiled:[],
            defaultFieldSelect:[],
            selectValue:[],
            defaultFieldCascade2:[],
            defaultFieldSingele:[],
            singeText:'',
            WebHookValue:'',
            options:[
                {value:'1',label:'1'},
                {value:'2',label:'2'},
                {value:'3',label:'3'}
            ],
            tableData: [],
            WebHookParameter:[],
          webhookParameter:[],
            show:true,
          associatedEntity:[],
          fieldPage:1,
          fieldCurrentSize:1000,
          fieldList:[{name:'',url:''}],
          webhookDirList:[],
          webhookApiList:[],
          webhookBaseList:[],
          formList:[],
          formPage:1,
          formCurrentSize:1000,
          form:{},
          departmentDid:'',
          release:false,
          dateValue:'',
          timeValue:'',
            postAction:"/api/oss/public",
            fileList:[{name:'233666666666666666666',url:'http://localhost:8081/d9e03655-1268-4c9a-8d5b-eb7619a62c3d'}],
            uploadAvatarParams: {
                token: "8c98087dfd2d48f856d8c95c09115def",
            },


        }
    },
    computed:{
        acceptCom:function(){
            return '.jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PBG,.GIF'
        },
        formTitleEdit:function(){
            let flag=false
            if ((this.$route.query.release===false ||this.$route.query.release==='false')&&this.$route.query.id){
                flag=true
            }
            return flag

        },
        formTitleLook:function () {
            let flag=false
            if ((this.$route.query.release===true ||this.$route.query.release==='true')&&this.$route.query.id){
                flag=true
            }
            return flag
        },
        filterAddFormFiled: function(arr){
          let tampArr = [];
          this.addFormFiled.forEach(item => {
            if (item.type != "EXPLANATION") {
              tampArr.push(item);
            }
          })
          return tampArr;
        }
    },
    methods:{
      getUrlKey (name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
      },

        // 获取机器人列表
        getBotList() {
          this.FetchGet(this.requestUrl.bot.getBotList, {
            page: 1,
            size: 1000,
          }).then((res) => {
            if (res.code === "0") {
              this.botOptions = res.data.list;
            } else {
              this.$message.error(res.message);
            }
          });
        },
        getCurrentUrl(){
            this.currentPath = this.$route.path;
            console.log('path:',this.currentPath);
        },
        //返回表单管理
        goToManageForm(){
            this.$router.push({
                name: 'formManage'
            });
        },

        //向右边添加字段
        fieldClick(item,name,index){

                this.fieldList.splice(index,1)
                this.addFormFiled.push(item)

        },
        getClass(item){

            if(item.type==='CASCADER'||item.type==='SELECT' ||item.type==='OPTION' ||item.type==='DATE_PICKER'||item.type==='TIME_PICKER' || item.type==='DATE_RANGE' || item.type ==='TIME_RANGE')
                return 'cell-manag'
            else if(item.type==='INPUT'||item.type==='RADIO'||item.type==='CHECKBOX'||item.type==='DATETIME_RANGE')
                return 'cell-user'
            else if(item.type==='TEXTAREA' || item.type==='EXPLANATION')
                return 'cell-textarea'
            else if (item.type==='FILE')
                return 'cell-file'
        },

        //调整右边字段的顺序
        userDrop() {

                const box = document.getElementById('box')
                const _this = this;
                new Sortable(box, {
                    ghostClass: 'blue-background-class',
                    animation: 500,
                    sort: true,
                  handle: '.handles',
                    onUpdate: function (event) {
                        //修改items数据顺序
                        let newIndex = event.newIndex,
                            oldIndex = event.oldIndex,
                            $li = box.children[newIndex],
                            $oldLi = box.children[oldIndex];
                        // 先删除移动的节点
                        box.removeChild($li)
                        // 再插入移动的节点到原有节点，还原了移动的操作
                        if (newIndex > oldIndex) {
                            box.insertBefore($li, $oldLi)
                        } else {
                            box.insertBefore($li, $oldLi.nextSibling)
                        }
                        // 更新items数组
                        let item = _this.addFormFiled.splice(oldIndex, 1)
                        _this.addFormFiled.splice(newIndex, 0, item[0])
                        // 下一个tick就会走patch更新

                    },


                });
                this.addFormFiled=  _this.addFormFiled
            // 此时找到的元素是要拖拽元素的父容器
        },

        //删除右边的字段
        delRightField(index,item){

                this.addFormFiled.splice(index,1)
                this.fieldList.push(item)


        },
        getRowClass(){
            return 'addFormClass'
        },
        //webHook值改变的时候
        changeWebHookValue(value){
          console.log(value)
            console.log(this.webhookBaseList)
          for (let i=0;i<this.webhookBaseList.length;i++)
          {
            if(this.webhookBaseList[i].id===value)
            {
              this.webhookParameter=this.webhookBaseList[i].params
            }
          }
            for (let i=0;i<this.addFormFiled.length;i++)
            {
              this.addFormFiled[i].extInfo.WebHookParameter=''
              this.addFormFiled[i].required = false

            }
        },
        selectWebHook(value,index) {
          let obj = this.webhookParameter.find(item =>{ return item.key == value })
          if(obj) {
            this.addFormFiled[index].required = obj.required
          }
        },
        changeRequired(value,scope) {
          let row = scope.row
          let webhook =  this.webhookParameter.find(item => { return item.key == row.extInfo.WebHookParameter })
          let index =  this.addFormFiled.findIndex(res => { return res.id == row.id })
          if(webhook && webhook.required) {
            let count = 0 
            this.addFormFiled.forEach(res =>{
              if(res.extInfo.WebHookParameter == row.extInfo.WebHookParameter && res.required ) {
                count++
              }
            })
            if(this.addFormFiled[index].required) {
              if(count > 1) {
                let required = !this.addFormFiled[index].required
                this.$set(this.addFormFiled[index],'required',required)
                // this.addFormFiled[index].required = 
                // this.$forceUpdate()
              } else {
                this.$message({
                  type:'warning',
                  message:'当前webHook字段为必须,至少保留一个必填项'
                })
              }
            } else {
              let required = !this.addFormFiled[index].required
              this.$set(this.addFormFiled[index],'required',required)
            }
          } else {
              let required = !this.addFormFiled[index].required
              this.$set(this.addFormFiled[index],'required',required)
          }
        },
        //获取全部表单中找具体某一个表单
        fillInfo(value){
            if(Object.keys(this.form).length > 0)
            {
                this.formName=this.form.name
                this.formDescribe=this.form.description
                this.botScope =  this.form.scope.scope
                this.selectBots = this.form.scope.botIds

                if (this.form.scope.bots != null && this.form.scope.bots.length != 0) {
                  let tampBotArr = [];
                  this.form.scope.bots.forEach(bot => {
                    let isExt = false;
                    this.botOptions.forEach(item => {
                      if (item.id == bot.id) {
                        isExt = true;
                      }
                    })
                    if (!isExt) {
                      tampBotArr.push(bot)
                    }
                  })
                  this.botOptions = this.botOptions.concat(tampBotArr);
                }

                if(this.form.webhookId!==0)
                {
                    this.WebHookValue=this.form.webhookId
                }
                console.log((!this.RP_Visible('OPEN_FORM_UPDATE') && this.RP_Visible('OPEN_FORM_RELEASE')), "+_+_+_+_+_++")
                if(this.form.release == true || (!this.RP_Visible('OPEN_FORM_UPDATE') && this.RP_Visible('OPEN_FORM_RELEASE')) || !this.$route.query.editable)
                {
                    this.IsDisabled=true
                }
                  if(value)
                  {
                      for (let j=0;j<this.form.formFieldRelation.length;j++){
                          for (let i=0;i<value.length;i++)
                          {
                               if(this.form.formFieldRelation[j].fieldId===value[i].id)
                                  {
                                      value[i].required = this.form.formFieldRelation[j].required
                                      this.addFormFiled.push(value[i])
                                  }
                          }
                      }
                  }
                for (let i=0;i<this.webhookBaseList.length;i++)
                {
                    if(this.webhookBaseList[i].id===this.form.webhookId)
                    {
                        this.webhookParameter=this.webhookBaseList[i].params
                    }
                }
                for (let i=0;i<this.addFormFiled.length;i++)
                {
                  for (let j =0;j<this.form.formFieldRelation.length;j++){
                    if (this.addFormFiled[i].id ===this.form.formFieldRelation[j].fieldId){
                      this.$set(this.addFormFiled[i].extInfo,'WebHookParameter',this.form.formFieldRelation[j].paramName)
                    }
                  }
                }
            }
            if(value)
            {
              var result = value.filter(item => {
                return this.addFormFiled.every(item2 => {
                  return item.id !== item2.id
                })
              })
              this.fieldList = result
            }
          console.log('formFieldRelation',this.form.formFieldRelation,this.addFormFiled)
        },
        //获取全部表单中找具体某一个表单
      getFormList(){
        let release=this.$route.query.release;
        if(release === false || release === 'false')
        {
          this.release=false
        }
        else
        {
          this.release=true
        }
        if (this.$route.query.id!==undefined){
          console.log(this.$route.query.release)
          if(this.$route.query.release === true || this.$route.query.release=== 'true')
          {
            this.FetchGet('/api/form/'+this.$route.query.id).then(res=>{
                if(res.code==='0'){
                    this.form=res.data;
                    console.log('form data',this.form);
                    let formFieldRelation = this.form.formFieldRelation;
                    let fieldIds = formFieldRelation.map((item) => {
                        return item.fieldId;
                    });
                    this.getField(release?fieldIds:null,this.fillInfo);
                }

            })
          }
          else if(release === false || release === 'false') {
            this.FetchGet('/api/form/'+this.$route.query.id).then(res=>{
                if(res.code==='0'){
                    this.form=res.data;
                    this.getField(null,this.fillInfo);
                }

            })
          }

        }else {
            this.getField(null,this.fillInfo);
        }
          },
        //获取Webhook的列表
       getWebHook(){
         this.FetchGet('/api/webhook/dir/list/',"keyword=''").then(res=>{
           if (res.code === "0") {
             let tampArr = [];
             this.webhookDirList = res.data;
             this.loading = false;
             let moToExtractNodeAsList = (target, item) => {
               if (item.type === "api" && item.status != 0) {
                 tampArr.push(item);
               } else {
                 item.children.forEach((cell) => {
                   moToExtractNodeAsList(tampArr, cell);
                 });
               }
             };
             res.data.forEach((item) => {
               moToExtractNodeAsList(tampArr, item);
             });

             this.webhookApiList = tampArr;
           } else {
             this.$message.error(res.message);
           }
         })
         this.FetchGet('/api/webhook/base-list?status=1').then(res=>{
           if(res.code==='0')
           {
             this.webhookBaseList=res.data;
             this.getFormList();
           }

         })

       },
      initReleatiopn(fieldIds){
        console.log(fieldIds)
        if(fieldIds!=null) {
          for (let i=0;i< this.addFormFiled.length;i++) {
            if( this.addFormFiled[i].type === 'SELECT' ||  this.addFormFiled[i].type==='RADIO' ||  this.addFormFiled[i].type ==='CHECKBOX') {
              for (let j=0;j< this.addFormFiled[i].extInfo.option.length;j++) {
                if( this.addFormFiled[i].extInfo.option[j].value === '') {
                  this.addFormFiled[i].extInfo.option[j].value = this.addFormFiled[i].extInfo.option[j].label
                }
              }
            }
          }
        } else { 
            for (let i=0;i<this.fieldList.length;i++) {
              if(this.fieldList[i].type === 'SELECT') {
                for (let j=0;j<this.fieldList[i].extInfo.option.length;j++) {
                  if(this.fieldList[i].extInfo.option[j].value === '') {
                    this.fieldList[i].extInfo.option[j].value = this.fieldList[i].extInfo.option[j].label
                  }
                }
              }
              if(this.fieldList[i].type==='RADIO') {
                for (let j=0;j<this.fieldList[i].extInfo.option.length;j++) {
                  if(this.fieldList[i].extInfo.option[j].value === '') {
                    this.fieldList[i].extInfo.option[j].value = this.fieldList[i].extInfo.option[j].label
                  }
                }
              }
              if( this.fieldList[i].type ==='CHECKBOX') {
                for (let j=0;j<this.fieldList[i].extInfo.option.length;j++) {
                  if(this.fieldList[i].extInfo.option[j].value === '')
                  {
                    this.fieldList[i].extInfo.option[j].value = this.fieldList[i].extInfo.option[j].label
                  }
                }
              }
          }
        }
      },
        //获取自定义字段
      getField(fieldIds,callback){
        console.log(fieldIds)
          if (fieldIds!=null){
              let query = '';
              for (let i=0,len=fieldIds.length;i<len;i++){
                  query+="fieldIds="+ fieldIds[i] + '&';
              }
              this.FetchGet('/api/form/fields?'+query).then(res=>{
                  this.addFormFiled = res.data.list;
                  this.initReleatiopn(fieldIds)
                  // console.log(this.form,this.addFormFiled);
                  this.form.formFieldRelation.forEach(item =>{
                    let index = this.addFormFiled.findIndex(element =>{return element.id == item.fieldId})
                    if(index != -1 ) {
                      this.addFormFiled[index].required = item.required
                    }
                  })
                  if (callback){
                      callback();
                  }
              })
          }else {
              this.FetchGet('/api/form/fields?page='+this.fieldPage+'&size='+this.fieldCurrentSize).then(res=>{
                  this.fieldList=res.data.list;
                 this.initReleatiopn(fieldIds)
                  console.log('fieldList',this.fieldList)
                  if (callback){
                      callback(this.fieldList);
                  }
              })
          }
      },

        //保存表单
        preserveForm(){
          console.log(this.fileList)
          if(this.formName==='')
          {
            this.$message({
              message:'请输入表单名称',
              type:'error',
              duration:2000
            })
            return
          }
          if (this.botScope == 1 && this.selectBots.length == 0) {
            this.$message({
              message:'请选择表单适用机器人',
              type:'error',
              duration:2000
            })
            return
          }
          let fieldIdList=[]
          let requier=[]
          for (let i=0;i<this.addFormFiled.length;i++)
          {
            // requier.push(false)
            // for (let j=0;j<this.webhookBaseList.length;j++)
            // {
            //   if(this.WebHookValue===this.webhookBaseList[j].id)
            //   {
            //     for (let k=0;k<this.webhookBaseList[j].params.length;k++)
            //     {
            //       if(this.webhookBaseList[j].params[k].key===this.addFormFiled[i].extInfo.WebHookParameter)
            //       {
            //           requier[i]=this.webhookBaseList[j].params[k].required
            //       }
            //     }
            //  }
            // }
            fieldIdList.push({fieldId:this.addFormFiled[i].id,paramName: this.addFormFiled[i].extInfo.WebHookParameter,required:this.addFormFiled[i].required})
          }
          let form
          form={
            description: this.formDescribe,
            name: this.formName,
            scope: {
              scope: this.botScope,
              botIds: this.selectBots
            },
            relations:fieldIdList,
            webhookId: this.WebHookValue
          }
          console.log('form',form)
          if(this.$route.query.id!==undefined){
            this.FetchPut('/api/form/',this.$route.query.id,form).then(res=>{
              if(res.code==='0')
              {
                this.$message.success('修改表单成功')
                this.$router.go(-1)
              }
            })
          }
          else
          {
            this.FetchPost('/api/form',form).then(res=>{
              if(res.code === '0')
              {
                this.$message.success('添加表单成功')
                this.$router.go(-1)
              }
            })
          }
        },
        //点击发布
        issueFormClick(){
            if(this.formName==='')
            {
                this.$message({
                    message:'请输入表单名称',
                    type:'error',
                    duration:1500
                })
            } else if (this.addFormFiled.length == 0) {
                this.$message({
                    message:'当前表单没有选择字段，请选择字段后发布',
                    type:'error',
                    duration:1500
                })
            } else if (this.botScope == 1 && this.selectBots.length == 0) {
                this.$message({
                  message:'请选择表单适用机器人',
                  type:'error',
                  duration:2000
                })
            }
            else {
                this.dialogVisible = true
            }
        },

        //发布表单
        publishForm(){
          if(this.formName==='')
          {
            this.$message({
              message:'请输入表单名称',
              type:'error',
              duration:2000
            })
            return
          }
          let fieldIdList=[]
          let requier=[]
          for (let i=0;i<this.addFormFiled.length;i++)
          {
            // requier.push(false)
            // for (let j=0;j<this.webhookBaseList.length;j++)
            // {
            //   if(this.WebHookValue===this.webhookBaseList[j].id)
            //   {
            //     for (let k=0;k<this.webhookBaseList[j].params.length;k++)
            //     {
            //       if(this.webhookBaseList[j].params[k].key===this.addFormFiled[i].extInfo.WebHookParameter)
            //       {
            //         requier[i]=this.webhookBaseList[j].params[k].required
            //       }
            //     }
            //   }
            // }
            fieldIdList.push({fieldId:this.addFormFiled[i].id,paramName: this.addFormFiled[i].extInfo.WebHookParameter,required:this.addFormFiled[i].required})
          }
          let form
          form={
            description: this.formDescribe,
            name: this.formName,
            scope: {
              scope: this.botScope,
              botIds: this.selectBots
            },
            relations:fieldIdList,
            webhookId: this.WebHookValue
          }
        if(this.$route.query.id!==undefined)
        {
          this.FetchPut('/api/form/',this.$route.query.id,form).then(res=>{
            if(res.code === '0')
            {
              this.FetchPut('/api/form/publish/',this.$route.query.id).then(res=>{
                if(res.code==='0')
                {
                  this.$message({
                    message: '表单发布成功',
                    type: 'success',
                    duration:1000
                  })
                  this.$router.go(-1)
                }
              })
            }
          })

          this.dialogVisible = false
        }
        else
        {
          this.FetchPost('/api/form',form).then(res=>{
            if(res.code === '0')
            {

              this.FetchGet('/api/form/page?page='+this.formPage+'&size='+this.formCurrentSize).then(res=>{
                if(res.code==='0')
                {

                  this.formList=res.data.list
                  let lastId=''
                  lastId=this.formList[this.formList.length-1].id
                  this.FetchPut('/api/form/publish/',lastId).then(res=>{
                    if(res.code==='0')
                    {
                      this.$message({
                        message: '表单发布成功',
                        type: 'success',
                        duration:1000
                      })
                      this.$router.go(-1)
                    }
                  })
                  this.dialogVisible = false
                }
              })
            }
          })

          this.dialogVisible = false
        }
        },
        handlePictureCardPreview(){},
        handleRemove(file){
          console.log(file)
            console.log(this.fileList)
        },
        handleAvatarSuccess(res,file){
          console.log(file)
           /* this.fileList = URL.createObjectURL(file.raw);*/
            let fileList=URL.createObjectURL(file.raw)
            console.log(fileList)
            let name=file.name
            let url=file.response
            this.fileList.push({name:name,url:url})
            console.log(this.fileList)
        }
    },
    mounted(){
        this.roleId = localStorage.getItem("roleId");
        if (this.roleId != 1) {
          this.botScope = 1;
        }
        if (this.$route.query.editable == undefined) {
          this.editable = true;
        } else {
            this.editable = this.$route.query.editable;
        }
        this.getCurrentUrl();
        this.getWebHook()
        this.getBotList();
    },
  updated() {
  },
  watch:{
    formList:function (a)
    {
      this.formList=a
    }
  }
}
</script>
<style lang="less">
@import "./../../assets/less/formManage/addForm.less";
.box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: -10px;
}
.cell {
    cursor: pointer;
    margin-bottom: 20px;
}
.cell-user {
    flex: none;
    width: 98.5%;
    height: 96px;
    background-color: #F2F2F2;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
.cell-textarea{
    width: 98.5%;
    height: 115px;
    background-color: #F2F2F2;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
.cell-manag {

    flex: none;
    width: calc(33.33% - 10px);
    height: 96px;
    background-color: #F2F2F2;
    margin-bottom: 20px;
    border-right: 10px solid white;
    position: relative;
}
.cell-file{
    flex: none;
    min-width: calc(33.33% - 10px);
    max-width: 100%;
    height: auto;
    background-color: #F2F2F2;
    margin-bottom: 20px;
    border-right: 10px solid white;
    position: relative;
}
    .addFormClass{
        height: 72px;
        line-height: 72px;
        padding: 0 !important;
    }
.el-table .cell{
    margin-bottom: 0;
}
.notClickClass{
  pointer-events: none;
}
#addForm{
    /deep/.el-scrollbar__wrap{
        overflow-x: hidden;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #366AFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .picture-show{
        background-color: #fbfdff;
        border: 1px dashed #c0ccda;
        border-radius: 6px;
        width: 148px;
        height: 148px;
        line-height: 146px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        font-size:28px;
        color:#8c939d;
    }
}
</style>