<template>
    <div class="optionset">
                <div class="optionSetNum handles" @mouseenter="resortOptionSet" @mouseover="mouseUpClick" style="cursor: move">
                  <img src="../../../assets/images/adjust1.png" style="width: 16px;height: 16px;cursor: move"/>
                </div>

                <div class="optionSetName" style="margin-left: -10px">
                    <el-input
                            v-model="content.label"
                            autocomplete="off"
                            placeholder="请输入选项名称"
                            style="width: 80%;"
                    ></el-input>
                </div>
                <div class="optionSetValue" style="margin-left: -10px">
                  <el-input
                      v-model="content.value"
                      autocomplete="off"
                      placeholder="请输入选项值"
                      style="width: 80%;"
                  ></el-input>
                </div>


      <div class="optionSetRadio">

                           <el-radio
                                   v-model="radio"
                                   :label="content._default"
                                   @change="radioChange(index2)"
                           >{{optionValueIsNull}}</el-radio>


                   </div>
                <div class="optionSetDel" @click="delClick(index)">
                   <img src="../../../assets/images/delred.png" style="width: 16px;height: 16px;cursor: pointer"/>
                </div>

    </div>

</template>

<script>
    export default {
        name: "OptionSet",
        data(){
            return {
                option: [],
                input:{
                    inputName:'',
                    radio:''
                },
                inputName:'',
                radio:'',
                checked: true,
                optionValueIsNull: '',
                addOption: ['1', '2'],
                value:0,
            }
        },
        mounted() {

            this.radioOptions.forEach(item=>{
                if(item._default==='true')
                    this.radio=item._default
            })
            console.log(this.radio)
        },

        props:{
            content:{
                type:Object
            },
            index:{
                type:Number
            },
            item:{
                type:String
            },
            itemsExtInfo:{
                type:Object,
                default(){
                    return {}
                }
            },
            selectOptions:Array,

            radioOptions:{
                type:Array,
                default(){
                    return []
                }
            },
            defaultValue:String,
            index2:Number

        },
        methods:{

          /*  change(value){
                this.radio=value
            },*/
            delClick(index){
               this.index1=index
                console.log(index)
                this.$emit('delClick',this.index1)
            },
            change(){

            },
            /*添加下拉选项的值*/
            optionInputClick(){
                this.$emit('optionInputClick',this.inputName)
               /* this.input.inputName=this.inputName
                this.input.radio=this.radio
                this.option.push(this.input)
                this.$emit('optionInputClick',this.option)*/
              /*  if(this.inputName!==''){
                    this.input.inputName=this.inputName
                    this.input.radio=this.radio
                   /!* this.$store.commit('optionAdd',this.input)*!/
                }*/
                   /* this.newField.extInfo.options.push(this.options)
                    this.$http.post('/api/field', this.newField).then(response => {
                        console.debug('post field response:', response);
                        console.log(this.response)
                    });*/
            },

            radioChange(index){
                for (let i =0;i<this.radioOptions.length;i++)
                {
                    this.radioOptions[i]._default='false'
                }

                this.radioOptions[index]._default='true'
                this.radio=this.radioOptions[index]._default
                /*for (let i =0;i<this.radioOptions.length;i++)
                {
                    if(this.radioOptions[i]._default==='true')
                        this.radio=this.radioOptions[i]._default
                }*/


             /*   this.$emit('radioChange',index)*/

            },

            resortOptionSet(){
                this.$emit('resortOptionSet')
            },
            mouseUpClick(){
                this.$emit('mouseUpClick')
            }
        },
        watch:{
            radioOptions:function (a) {
                a.forEach(item=>{
                    if(item._default==='true')
                        this.radio=item._default
                })
                console.log(a)
            }
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/less/formManage/optionset/optionset.less";
</style>