<template>
    <div class="p-version-tip">
        <!-- <div class="popup-name">无法访问</div> -->
        <div class="popup-tip">您当前的版本无法使用该功能</div>
        <div class="popup-con">
            <div class="version-tip">
                <!-- <div class="vt-name">
                    <span class="arsenal_icon arsenalshiticengguanli"></span
                    >{{ tipList[Number(tipIndex)].name }}
                </div> -->
                <div class="vt-content">
                    {{ tipList[Number(tipIndex)].content }}
                </div>
                <div class="vt-point">
                    {{ tipList[Number(tipIndex)].point }}
                </div>
            </div>
        </div>
        <div slot="dialog-footer">
            <el-button
                type="primary"
                size="small"
                @click="versionTipDialog = false"
                >拨打 4001-789-800 升级</el-button
            >
        </div>
    </div>
</template>
<script>
export default {
    props: ["tipIndex"],
    data() {
        return {
            tipList: [
                {
                    name: "我的知识库模板",
                    content:
                        "用于管理企业自有知识库，可将企业自有知识库作为基础模板供其他机器人使用，知识库模板更新会自动同步到其他机器人中，常用于不同大区意图相同但答案不同等场景。",
                },
                {
                    name: "WebHook",
                    content:
                        "可实现企业业务系统的数据与机器人打通，通过机器人调用业务系统的某些功能。应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "WebHook组件",
                    content:
                        "可在对话流程中调用WebHook接口，实现企业业务系统的数据与机器人打通，通过机器人调用业务系统的某些功能。应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "机器人管理",
                    content:
                        "您当前的机器人已超出版本可创建数量，如您想要创建更多机器人，请联系我们~",
                },
                {
                    name: "字段管理与表单管理",
                    content:
                        "可配置表单的字段与表单字段布局，与WebHook配合使用。应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "提交表单组件",
                    content:
                        "可将机器人的数据通过表单提交给业务系统。应用场景举例：查库存、查年假、查天气、预约会议室、物料申领等。",
                },
                {
                    name: "意图管理",
                    content:
                        "您当前的意图已超出版本可创建数量，如您想要创建更多意图，请联系我们~",
                    point: "注：可将已关闭的意图删除释放可创建意图数。",
                },
            ],
        };
    },
};
</script>
<style lang="less" scoped>
.p-version-tip{
    width: 500px;
    height: 500px;
    margin: calc(50vh - 350px) auto;
    background-image: url('./../../assets/images/no-acc.png');
    background-size:  500px auto;
    background-repeat: no-repeat;
    padding-top: 300px;
}
.popup-name {
    font-size: 26px !important;
    color: #a9b3c6;
}
.popup-tip {
    font-size: 14px;
    padding-top: 18px;
}
.version-tip {
    .vt-name {
        height: 48px;
        line-height: 48px;
        background: #f6f8fd;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        color: #366aff;
        .arsenal_icon {
            display: inline-block;
            font-size: 15px;
            margin-right: 6px;
        }
    }
    .vt-content {
        margin-top: 24px;
        text-align: center;
        color: #a9b3c6;
    }

    .vt-point {
        margin-top: 16px;
        font-size: 12px;
        color: #a9b3c6;
        text-align: left;
    }
}
</style>